import { SharedService } from "./shared.service";
import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";

/* import {Store} from '@ngxs/store/';
import Fortmatic from "fortmatic";
import {Router} from "@angular/router"; */

import { ApiService } from "../services/api.service";
import { Store } from "@ngxs/store/";
import Fortmatic from "fortmatic";
import { Router } from "@angular/router";
import { LoginState } from "app/store/login/login.state";
import { UserStateModel } from "app/store/user/user.state";

import "rxjs/add/operator/do";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    //constructor(private store: Store,private router: Router, private sharedService: SharedService) { }

    constructor(private store: Store, private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).do(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        /*           this.sharedService.setSelectedRoleData("");
         
          fm.user.logout();
          localStorage.clear();
          this.router.navigate(["/login"]); */

                        localStorage.removeItem("currentSelectedRole");
                        const fm = new Fortmatic("pk_live_DF44F7660C691718");
                        fm.user.logout();
                        localStorage.clear();
                        // this.store.reset([LoginState,UserStateModel ]);
                        this.router.navigate(["/login"]);
                    } else {
                    }
                }
            }
        );
    }
}
