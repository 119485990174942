import { typeSourceSpan } from "@angular/compiler";

export function events(id?: string) {
    return "/requests/event" + (id ? "/" + id : "");
}
export function eventstype(): string {
    return "/dropdown/event_type";
}
export function eventRegistration(id: string, regID?: string) {
    return "/requests/event/" + id + "/register" + (regID ? "/" + regID : "");
}

export function proposals(type: string): string {
    return "/requests/filter/proposal/" + type;
}

export function activeEvents(): string {
    return "/requests/active-events/";
}
