import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DateTimeformat, grievianceRequestTypes } from "app/utils/masterConfig";
import { Actions, Store } from "@ngxs/store";
import { createProtocol } from "app/store/protocols/protocols.actions";
import { environment } from "environments/environment";
import { getImageByID } from "app/store/user/user.api";
import { getProposalDetails } from "app/store/proposal/proposal.actions";

@Component({
    selector: "proposal-details-dialog",
    templateUrl: "./proposal-details-dialog.html",
    styles: [
        `
            form {
                width: 600px;
            }
        `,
    ],
})
export class ProposalDetailsDialog {
    direction = "row";
    requestForm: FormGroup;
    submitted = false;
    artifacts: Array<any>;
    requestTypes: Array<any> = grievianceRequestTypes;
    priorityList: Array<string> = ["low", "medium", "high"];
    editNonEdit: any;
    proposalDetails;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProposalDetailsDialog>,
        public actions: Actions,
        private fb: FormBuilder,
        private store: Store
    ) {
        this.editNonEdit = this.data.viewType;
        this.store.dispatch(new getProposalDetails(this.data.proposalID, "GC"));
        this.artifacts = [];
    }

    ngOnInit(): void {
        this.createForm();

        this.store
            .select((state) => state.proposal)
            .subscribe((res) => {
                this.proposalDetails = res && res["data"] && res["data"][0];
                if (this.proposalDetails) {
                    this.createForm();
                }
            });
    }

    createForm() {
        this.requestForm = this.fb.group({
            requestType: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.requestType,
                    disabled: true,
                },
            ],
            requestedObjectName: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.requestedObjectName,
                    disabled: true,
                },
            ],
            priority: [
                {
                    value:
                        this.proposalDetails && this.proposalDetails.priority,
                    disabled: this.editNonEdit == "details" ? true : false,
                },
            ],
            entityID: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.entityDetails &&
                        this.proposalDetails.entityDetails[0].entityName,
                    disabled: true,
                },
            ],
            processor: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.processorDetails &&
                        this.proposalDetails.processorDetails[0].userFirstName +
                            " " +
                            this.proposalDetails.processorDetails[0]
                                .userLastName,
                    disabled: this.editNonEdit == "details" ? true : false,
                },
            ],
            status: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.requestStatus,
                    disabled: this.editNonEdit == "details" ? true : false,
                },
            ],
            comment: [
                {
                    value: this.proposalDetails && this.proposalDetails.comment,
                    disabled: this.editNonEdit == "details" ? true : false,
                },
            ],
            description: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.requestDescription,
                    disabled: this.editNonEdit == "details" ? true : false,
                },
            ],
            createdBy: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.createdByDetails &&
                        this.proposalDetails.createdByDetails[0].userFirstName +
                            " " +
                            this.proposalDetails.createdByDetails[0]
                                .userLastName,
                    disabled: true,
                },
            ],
            createdOn: [
                {
                    value:
                        this.proposalDetails &&
                        DateTimeformat(this.proposalDetails.createdOn),
                    disabled: true,
                },
            ],
            updatedBy: [
                {
                    value:
                        this.proposalDetails &&
                        this.proposalDetails.updatedByDetails &&
                        this.proposalDetails.updatedByDetails[0].userFirstName +
                            " " +
                            this.proposalDetails.updatedByDetails[0]
                                .userLastName,
                    disabled: true,
                },
            ],
            updatedOn: [
                {
                    value:
                        this.proposalDetails &&
                        DateTimeformat(this.proposalDetails.updatedOn),
                    disabled: true,
                },
            ],
            payout: [
                {
                    value: this.proposalDetails && this.proposalDetails.payout,
                    disabled: true,
                },
            ],
        });
    }

    requestSubmit() {
        this.submitted = true;
        if (this.requestForm.invalid) {
            return;
        }

        let request = this.requestForm.value;
        request.artifacts = this.artifacts;
        let requestType = request.requestType;
        //  return;
        this.store.dispatch(new createProtocol(requestType, request));
        this.closeRequestDialog();
    }

    closeRequestDialog(): void {
        this.dialogRef.close();
    }
    insertArtifactID(id) {
        this.artifacts.push({
            id: id,
            url: environment.baseUrl + getImageByID(id),
        });
    }
}
