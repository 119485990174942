export class ShowSnackbarSuccess {
    static readonly type = "[snackbar] success ";
    constructor(public payload: any) {}
}
export class ShowSnackError {
    static readonly type = "[snackbar] error ";
    constructor(public payload: any) {}
}
export class ShowSnackbarWarning {
    static readonly type = "[snackbar] warning ";
    constructor(public payload: any) {}
}
