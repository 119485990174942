import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { masterConfig } from "../../utils/masterConfig";
import {
    CCRolesData,
    EventTypeData,
    GCRolesData,
    MasterData,
    ObjectNameData,
    RegisterMasterData,
    RegisterRequestStatusLifeCycleData,
    RegisterRequestTypeData,
    RegisterTypeData,
    RolesData,
    StatData,
    UserRolesData,
    VacancyTypeData,
} from "./master.data.model";
import { ApiService } from "../../services/api.service";
import {
    getAssociatedCharacteristicsData,
    getAssociatedProcessesData,
    getCCRolesData,
    getCharacteristicTypeData,
    getDomainMasterData,
    getEntityTypeData,
    getEventTypeData,
    getGCRolesData,
    getMarketplaceRequestTransactionTypeData,
    getMasterData,
    getMasterTableRoleData,
    getMasterTypeData,
    getObjectNameData,
    getObjectTypeData,
    getParameterTypeData,
    getPriorityTypeData,
    getProcessTypeData,
    getProtocolTypeData,
    getRegisterAdTypeTimelineData,
    getRegisterCharacteristicTypeMasterData,
    getRegisterDomainMasterData,
    getRegisterEntityProcessCharMasterData,
    getRegisterEntityProcessMasterData,
    getRegisterEntityTypeMasterData,
    getRegisterMasterAdTypeData,
    getRegisterMasterData,
    getRegisterMasterTypeData,
    getRegisterProcessTypeMasterData,
    getRegisterRelationshipTypeMasterData,
    getRegisterRequestEventLinkData,
    getRegisterRequestObjectLinkData,
    getRegisterRequestProtocolLinkData,
    getRegisterRequestStatusLifeCycleData,
    getRegisterRequestTypeData,
    getRegisterRequestVacancyLinkData,
    getRegisterSponsorshipMasterData,
    getRegisterStatisticParametersMasterData,
    getRegisterVotingStatusData,
    getRegisterXpostFrequencyMasterData,
    getRelationshipDirectionData,
    getRelationshipTypeData,
    getRequestTypeData,
    getRolesData,
    getSponsorshipTypeData,
    getStatData,
    getTransactionTypeData,
    getUserRequestTypeListData,
    getUserRequestTypeMasterData,
    getUserRolesData,
    getVacancyTypeData,
} from "./master.data.actions";
import {
    associatedCharacteristicsData,
    associatedProcessesData,
    CCRoleData,
    characteristicTypeData,
    domainMasterData,
    entityTypeData,
    eventTypeData,
    GCRoleData,
    marketplaceRequestTransactionTypeData,
    masterTableRoleData,
    masterTypeData,
    objectNameData,
    objectTypeData,
    parameterTypeData,
    priorityTypeData,
    processTypeData,
    protocolTypeData,
    registerAdTypeData,
    registerAdTypeTimelineData,
    registerCharacteristicTypeMasterData,
    registerDomainMasterData,
    registerEntityProcessCharMasterData,
    registerEntityProcessMasterData,
    registerEntityTypeMasterData,
    registerMasterData,
    registerMasterTypeData,
    registerProcessTypeMasterData,
    registerRelationshipTypeMasterData,
    registerRequestEventLinkData,
    registerRequestObjectLinkData,
    registerRequestProtocolLinkData,
    registerRequestStatusLifeCycleData,
    registerRequestTypeData,
    registerRequestVacancyLinkData,
    registerSponsorshipMasterData,
    registerStatisticParametersMasterData,
    registerVotingStatusData,
    registerXpostFrequencyMasterData,
    relationshipDirectionData,
    relationshipTypeData,
    requestTypeData,
    roleData,
    sponsorshipTypeData,
    statData,
    transactionTypeData,
    userRequestTypeData,
    userRequestTypeListData,
    userRoleData,
    vacancyTypeData,
} from "./master.api";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SharedService } from "app/services/shared.service";

@State<MasterData>({
    name: "masterData",
    defaults: {
        config: null,
        data: null,
    },
})
export class MasterDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getMasterData)
    getMasterData(
        { getState, patchState }: StateContext<MasterData>,
        { payload }: any
    ) {
        patchState({ config: masterConfig, data: [] });
    }
}

@State<RegisterMasterData>({
    name: "registerMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterMasterData)
    getRegisterMasterData(
        { getState, patchState }: StateContext<RegisterMasterData>,
        {}: any
    ) {
        this.api
            .get(registerMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterMasterData>({
    name: "registerMasterTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterMasterTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterMasterTypeData)
    getRegisterMasterTypeData(
        { getState, patchState }: StateContext<RegisterMasterData>,
        {}: any
    ) {
        this.api
            .get(registerMasterTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}
@State<RegisterMasterData>({
    name: "registerAdTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterAdTypeDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterMasterAdTypeData)
    getRegisterMasterAdTypeData(
        { getState, patchState }: StateContext<RegisterMasterData>,
        {}: any
    ) {
        this.api
            .get(registerAdTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterRequestStatusLifeCycleData>({
    name: "registerRequestStatusLifeCycleData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRequestStatusLifeCycleState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestStatusLifeCycleData)
    getRegisterRequestStatusLifeCycleData(
        {
            getState,
            patchState,
        }: StateContext<RegisterRequestStatusLifeCycleData>,
        {}: any
    ) {
        this.api
            .get(registerRequestStatusLifeCycleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterRequestTypeData>({
    name: "registerRequestTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRequestTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestTypeData)
    getRegisterRequestTypeData(
        { getState, patchState }: StateContext<RegisterRequestTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRequestTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterRequestTypeData>({
    name: "userRequestTypeListData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class UserRequestTypeListState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getUserRequestTypeListData)
    getUserRequestTypeListData(
        { getState, patchState }: StateContext<RegisterRequestTypeData>,
        {}: any
    ) {
        this.api
            .get(userRequestTypeListData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<GCRolesData>({
    name: "gcRolesData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class GCRolesState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getGCRolesData)
    getGCRolesData(
        { getState, patchState }: StateContext<GCRolesData>,
        {}: any
    ) {
        this.api
            .get(GCRoleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<CCRolesData>({
    name: "ccRolesData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class CCRolesState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getCCRolesData)
    getCCRolesData(
        { getState, patchState }: StateContext<CCRolesData>,
        {}: any
    ) {
        this.api
            .get(CCRoleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<UserRolesData>({
    name: "userRolesData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class UserRolesState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getUserRolesData)
    getUserRolesData(
        { getState, patchState }: StateContext<UserRolesData>,
        {}: any
    ) {
        this.api
            .get(userRoleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RolesData>({
    name: "rolesData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RolesState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRolesData)
    getRolesData({ getState, patchState }: StateContext<RolesData>, {}: any) {
        this.api
            .get(roleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<VacancyTypeData>({
    name: "vacancyData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class VacancyTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getVacancyTypeData)
    getVacancyTypeData(
        { getState, patchState }: StateContext<RolesData>,
        {}: any
    ) {
        this.api
            .get(vacancyTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<ObjectNameData>({
    name: "objectNameData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class ObjectNameState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getObjectNameData)
    getObjectNameData(
        { getState, patchState }: StateContext<RolesData>,
        {}: any
    ) {
        this.api
            .get(objectNameData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<StatData>({
    name: "statData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class StatState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getStatData)
    getStatData({ getState, patchState }: StateContext<RolesData>, {}: any) {
        this.api
            .get(statData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<EventTypeData>({
    name: "eventTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class EventTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getEventTypeData)
    getEventTypeData(
        { getState, patchState }: StateContext<RolesData>,
        {}: any
    ) {
        this.api
            .get(eventTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "domainMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class DomainMaster {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getDomainMasterData)
    getDomainMasterData(
        { getState, patchState }: StateContext<RolesData>,
        {}: any
    ) {
        this.api
            .get(domainMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "requestVacancyLinkData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RequestVacancyLinkState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestVacancyLinkData)
    getRegisterRequestVacancyLinkData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRequestVacancyLinkData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerRequestObjectLinkData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRequestObjectLinkState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestObjectLinkData)
    getRegisterRequestObjectLinkData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRequestObjectLinkData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerRequestEventLinkData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRequestEventLinkState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestEventLinkData)
    getRegisterRequestEventLinkData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRequestEventLinkData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerRequestProtocolLinkData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRequestProtocolLinkState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRequestProtocolLinkData)
    getRegisterRequestProtocolLinkData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRequestProtocolLinkData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerVotingStatusData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterVotingStatusState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterVotingStatusData)
    getRegisterVotingStatusData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerVotingStatusData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerEntityTypeMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterEntityTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterEntityTypeMasterData)
    getRegisterEntityTypeMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerEntityTypeMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerDomainMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterDomainMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterDomainMasterData)
    getRegisterDomainMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerDomainMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerProcessTypeMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterProcessTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterProcessTypeMasterData)
    getRegisterProcessTypeMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerProcessTypeMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerCharacteristicTypeMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterCharacteristicTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterCharacteristicTypeMasterData)
    getRegisterCharacteristicTypeMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerCharacteristicTypeMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerRelationshipTypeMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterRelationshipTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterRelationshipTypeMasterData)
    getRegisterRelationshipTypeMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerRelationshipTypeMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerEntityProcessMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterEntityProcessMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterEntityProcessMasterData)
    getRegisterEntityProcessMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerEntityProcessMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerEntityProcessCharMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterEntityProcessCharMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterEntityProcessCharMasterData)
    getRegisterEntityProcessCharMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerEntityProcessCharMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerSponsorshipMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterSponsorshipMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterSponsorshipMasterData)
    getRegisterSponsorshipMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerSponsorshipMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerAdTypeTimelineData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterAdTypeTimelineState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterAdTypeTimelineData)
    getRegisterAdTypeTimelineData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerAdTypeTimelineData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerXpostFrequencyMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterXpostFrequencyMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterXpostFrequencyMasterData)
    getRegisterXpostFrequencyMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerXpostFrequencyMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "registerStatisticParametersMasterData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RegisterStatisticParametersMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRegisterStatisticParametersMasterData)
    getRegisterStatisticParametersMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(registerStatisticParametersMasterData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "protocolTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class ProtocolTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProtocolTypeData)
    getProtocolTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(protocolTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "associatedProcessesMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class AssociatedProcessesMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getAssociatedProcessesData)
    getAssociatedProcessesData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(associatedProcessesData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "associatedCharacteristicsMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class AssociatedCharacteristicsMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getAssociatedCharacteristicsData)
    getAssociatedCharacteristicsData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(associatedCharacteristicsData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "entityTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class EntityTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getEntityTypeData)
    getEntityTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(entityTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "processTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class ProcessTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProcessTypeData)
    getProcessTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(processTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "relationshipTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RelationshipTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRelationshipTypeData)
    getRelationshipTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(relationshipTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "relationshipDirectionMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RelationshipDirectionMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRelationshipDirectionData)
    getRelationshipDirectionData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(relationshipDirectionData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "characteristicTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class CharacteristicTypeDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getCharacteristicTypeData)
    getCharacteristicTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(characteristicTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "sponsorshipTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class SponsorshipTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getSponsorshipTypeData)
    getSponsorshipTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(sponsorshipTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "objectTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class ObjectTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getObjectTypeData)
    getObjectTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(objectTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "parameterTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class ParameterTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getParameterTypeData)
    getParameterTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(parameterTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "priorityTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class PriorityTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getPriorityTypeData)
    getPriorityTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(priorityTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "userRequestTypeMaster",
    defaults: {
        msg: "",
        data: null,
    },
})
export class UserRequestTypeMasterState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getUserRequestTypeMasterData)
    getUserRequestTypeMasterData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(userRequestTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "masterType",
    defaults: {
        msg: "",
        data: null,
    },
})
export class MasterTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getMasterTypeData)
    getMasterTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(masterTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "masterTableRoleData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class MasterTableRoleDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getMasterTableRoleData)
    getMasterTableRoleData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(masterTableRoleData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "marketplaceRequestTransactionTypeDats",
    defaults: {
        msg: "",
        data: null,
    },
})
export class MarketplaceRequestTransactionTypeDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getMarketplaceRequestTransactionTypeData)
    getMarketplaceRequestTransactionTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(marketplaceRequestTransactionTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "requestTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class RequestTypeDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getRequestTypeData)
    getRequestTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(requestTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<RegisterTypeData>({
    name: "transactionTypeData",
    defaults: {
        msg: "",
        data: null,
    },
})
export class TransactionTypeDataState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getTransactionTypeData)
    getTransactionTypeData(
        { getState, patchState }: StateContext<RegisterTypeData>,
        {}: any
    ) {
        this.api
            .get(transactionTypeData())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}
