import { CookieService } from "ngx-cookie-service";
import { SharedService } from "./../../services/shared.service";
import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { FuseConfigService } from "@fuse/services/config.service";
import { Actions, Store } from "@ngxs/store";
import { fuseAnimations } from "../../../@fuse/animations/index";
import { MatDialog } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import Fortmatic from "fortmatic";
import { ApiService } from "app/services/api.service";
import { LoginFortmaticAction } from "app/store/login/login.actions";
import { AccessCodeComponent } from "./access-code/access-code.component";
import { VARIABLE_CONSTANT } from "@fuse/utils/constant";

@Component({
    selector: "user-login",
    templateUrl: "./userLogin.component.html",
    styleUrls: ["./userLogin.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class userLoginComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

    config: any;
    showLoader = false;
    loginForm: any;
    _formBuilder: FormBuilder;
    accessCodeVerified = false;
    connectionLoader = false;
    token;

    constructor(
        private api: ApiService,
        private _fuseConfigService: FuseConfigService,
        public actions: Actions,
        private router: Router,
        public store: Store,
        public dialog: MatDialog,
        private sharedService: SharedService,
        private cookieService: CookieService
    ) {
        this.token = this.cookieService.get("token");
        // Fully customizable surroundings for this particular component
        this._fuseConfigService.config = {
            layout: {
                style: "vertical-layout-1",
                width: "fullwidth",
                navbar: {
                    primaryBackground: "fuse-navy-700",
                    secondaryBackground: "fuse-navy-900",
                    folded: false,
                    hidden: true,
                    position: "left",
                    variant: "vertical-style-1",
                },
                toolbar: {
                    customBackgroundColor: false,
                    background: "fuse-white-500",
                    hidden: true,
                    position: "below-static",
                },

                sidepanel: {
                    hidden: true,
                    position: "right",
                },
            },
            customScrollbars: true,
        };
    }
    ngOnInit(): void {
        this.store
            .select((s) => s.user.user)
            .subscribe((u) => {
                if (u != "undefined" && u != null && u != "") {
                    this.store.dispatch(new Navigate(["/user/dashboard"]));
                }
            });
        if (this.accessCodeVerified) {
            this.loginWithFortmatic();
        } else {
            this.accessCodeLogin();
        }
    }
    onInit() {
        // Subscribe to config change
        this._fuseConfigService.config.subscribe((config) => {
            this.config = config;
        });
    }
    accessCodeLogin(): void {
        if (this.token) return;
        const dialogRef = this.dialog.open(AccessCodeComponent, {
            panelClass: "access-code-dialog",
            data: {
                ReturnData: "",
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.success) {
                this.accessCodeVerified = true;
                this.loginWithFortmatic();
            } else {
                this.accessCodeVerified = false;
                this.sharedService.onShowMessageBar(result.msg);
            }
        });
    }

    // loginWithGovernance() {
    //     const fm = new Fortmatic("");
    //     fm.getProvider().isFortmatic;
    //     try {
    //         fm.user.login().then((token) => {
    //             let userData = fm.user.getUser().then(async (userdata) => {
    //                 let fData = {
    //                     isLoggedIn: false,
    //                     token: null,
    //                     qrCode: null,
    //                     userID: userdata.userId,
    //                     userEmail: userdata.email,
    //                     loginSessionID: null,
    //                 };
    //                 this.store.dispatch(new LoginFortmaticAction(fData));
    //             });
    //         });
    //     } catch (err) {
    //         this.sharedService.onShowMessageBar(err);
    //     }
    // }

    loginWithFortmatic(): void {
        //this.accessCodeVerified = false;
        const fm = new Fortmatic("pk_live_DF44F7660C691718"); //pk_test_163E274EA5F264A2

        this.toggleLoader(true);
        fm.getProvider().isFortmatic;

        fm.configure().then((res) => {
            this.toggleLoader(false);
            let accessToken = "";
            let LoggedIn = false;
            fm.user.login().then((token: any) => {
                if (token !== "undefined" || token != "") {
                    let userData = fm.user
                        .getUser()
                        .then(async (userdata) => {
                            let fData = {
                                isLoggedIn: false,
                                token: null,
                                qrCode: null,
                                userID: userdata.userId,
                                userEmail: userdata.email,
                                loginSessionID: null,
                            };

                            await this.api
                                .loginFortmatic(fData)
                                .then(async (res) => {
                                    if (res && res.data.user) {
                                        await localStorage.setItem(
                                            "userId",
                                            res.data.user._id
                                        );
                                        await localStorage.setItem(
                                            "token",
                                            res.data.token
                                        );

                                        this.cookieService.set(
                                            "userId",
                                            res.data.user._id,
                                            VARIABLE_CONSTANT.expiryDate,
                                            VARIABLE_CONSTANT.defaultPath,
                                            VARIABLE_CONSTANT.DOMAIN
                                        );
                                        this.cookieService.set(
                                            "token",
                                            res.data.token,
                                            VARIABLE_CONSTANT.expiryDate,
                                            VARIABLE_CONSTANT.defaultPath,
                                            VARIABLE_CONSTANT.DOMAIN
                                        );

                                        this.cookieService.set(
                                            "user",
                                            JSON.stringify(res.data.user),
                                            VARIABLE_CONSTANT.expiryDate,
                                            VARIABLE_CONSTANT.defaultPath,
                                            VARIABLE_CONSTANT.DOMAIN
                                        );

                                        this.cookieService.set(
                                            "userKYC",
                                            res.data.user.userKYCFlag,
                                            VARIABLE_CONSTANT.expiryDate,
                                            VARIABLE_CONSTANT.defaultPath,
                                            VARIABLE_CONSTANT.DOMAIN
                                        );
                                        if (
                                            res.data.user.userKYCFlag ==
                                            undefined
                                        ) {
                                            this.cookieService.set(
                                                "userKYC",
                                                "false",
                                                VARIABLE_CONSTANT.expiryDate,
                                                VARIABLE_CONSTANT.defaultPath,
                                                VARIABLE_CONSTANT.DOMAIN
                                            );
                                        }

                                        // environment.userId = res.data.user._id;
                                        fData.token = res.data.token;
                                        //if (res.data.user.isNewUser) {
                                    }
                                });
                            this.store.dispatch(
                                new LoginFortmaticAction(fData)
                            );
                        })
                        .catch((e) => {});
                }
            });
        });
    }

    toggleLoader(flag: boolean): void {
        this.connectionLoader = flag;
    }
}
