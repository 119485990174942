import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./main/shared/components/page404/page404.component";
import { Page500Component } from "./main/shared/components/page500/page500.component";
import { ROUTES } from "./main/shared/constants";
import { AuthGuardService } from "./services/auth.guard.service";

const routes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
    },
    // {
    //     path: "**",
    //     redirectTo: "login",
    //     pathMatch: "full",
    // },
    {
        path: ROUTES.USER_LOGIN_MODULE,
        loadChildren: () =>
            import("./main/login-module/login.module").then(
                (m) => m.LoginModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.CURATION_MODULE,
        loadChildren: () =>
            import(
                "./main/curation-council-module/curation-council.module"
            ).then((m) => m.CurationCouncilModule),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.GOVERNANCE_MODULE,
        loadChildren: () =>
            import(
                "./main/governance-council-module/governance-council.module"
            ).then((m) => m.GovernanceCouncilModule),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.VACANCIES_MODULE,
        loadChildren: () =>
            import("./main/vacancies-module/vacancy.module").then(
                (m) => m.VacanciesModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.MANAGE_REQUEST_MODULE,
        loadChildren: () =>
            import("./main/requests-module/requests.module").then(
                (m) => m.RequestsModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.EVENTS_MODULE,
        loadChildren: () =>
            import("./main/events-module/events.module").then(
                (m) => m.EventsModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.USER_PROFILE_MODULE,
        loadChildren: () =>
            import("./main/userProfile-module/userProfile.module").then(
                (m) => m.userProfileModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: ROUTES.VACANCY_MODULE,
        loadChildren: () =>
            import("./main/vacancies-module/vacancy.module").then(
                (m) => m.VacanciesModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "404",
        component: Page404Component,
    },
    {
        path: "500",
        component: Page500Component,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
