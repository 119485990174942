export function vacancies(): string {
    return "/requests/vacancy";
}

export function vacanciesTypes(): string {
    return "/dropdown/vacancy_type";
}

export function events(id?: string) {
    return "/requests/event" + (id ? "/" + id : "");
}

export function eventRegistration(id: string, regID?: string) {
    return "/requests/event" + id + "/register" + (regID ? "/" + regID : "");
}

export function vacancy(id?: string) {
    return "/requests/vacancy/" + (id ? "/" + id : "");
}

export function vacancyRegistration(id: string, regID?: string) {
    return "/requests/vacancy/" + id + "/register" + (regID ? "/" + regID : "");
}

export function activeVacancies(): string {
    return "/requests/active-vacancies";
}
