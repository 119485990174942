// user.action.ts

import { State, Action, StateContext, Selector } from "@ngxs/store";
import { User } from "./user.model";
import { UpdateUser } from "./user.actions";

export class UserStateModel {
    user?: User;
    ccFlag?: boolean;
    gcFlag?: boolean;
}

@State<UserStateModel>({
    name: "user",
    defaults: {
        user: null,
        ccFlag: null,
        gcFlag: null,
    },
})
export class UserState {
    @Selector()
    static getUser(state: UserStateModel) {
        return state.user;
    }

    @Action(UpdateUser)
    updateUser(
        { getState, patchState }: StateContext<UserStateModel>,
        { payload }: UpdateUser
    ) {
        const state = getState();
        patchState({
            user: payload,
        });
    }
}
