import { SharedService } from "./../../../services/shared.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ApiService } from "app/services/api.service";

@Component({
    selector: "app-access-code",
    templateUrl: "./access-code.component.html",
    styleUrls: ["./access-code.component.scss"],
})
export class AccessCodeComponent implements OnInit {
    public addAccessCodeForm: FormGroup;
    constructor(
        public dialogRef: MatDialogRef<AccessCodeComponent>,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.createAccessCodeForm();
    }

    createAccessCodeForm() {
        this.addAccessCodeForm = this.fb.group({
            accessCode: ["", [Validators.required]],
        });
    }

    getAccessCode(form: FormGroup) {
        if (form.valid) {
            this.api.accessCodeApi(form.value).subscribe((res) => {
                if (res.success) {
                    this.sharedService.onShowMessageBar(res.msg);
                    this.dialogRef.close(res);
                } else {
                    this.sharedService.onShowMessageBar(res.msg);
                }
            });
        }
    }
}
