import { Login } from "./login.model";

export class LoginFortmaticAction {
    static readonly type = "[LoginFortmaticAction] Uport";

    constructor(public payload: Login) {}
}
export class LoginFortmatic {
    static readonly type = "[FortmaticLogin] Formatic";
    constructor(public payload: any) {}
}
export class Logout {
    static readonly type = "[Logout] Uport";

    constructor() {}
}

export class LoginUportSuccess {
    static readonly type = "[Login] Uport Success";

    constructor() {}
}

export class LoginUportError {
    static readonly type = "[Login] Uport Error";

    constructor() {}
}

export class VerifyUport {
    static readonly type = "[Verify] Uport";

    constructor(public payload: any) {}
}

export class ShowQr {
    static readonly type = "[ShowQr] Uport";

    constructor(public payload: any) {}
}

export class HideQr {
    static readonly type = "[HideQr] Uport";

    constructor() {}
}

export class SetAccessToken {
    static readonly type = "[HideQr] Uport";

    constructor(public payload: any) {}
}

export class ShowVerifyDialog {
    static readonly type = "[ShowVerifyDialog] Login";

    constructor() {}
}

export class HideVerifyDialog {
    static readonly type = "[HideVerifyDialog] Login";

    constructor(public payload: any) {}
}

export class VerificationComplete {
    static readonly type = "[VerificationComplete] Login";

    constructor() {}
}

export class VerificationFailed {
    static readonly type = "[VerificationFailed] Login";

    constructor(public payload: any) {}
}

export class SetLoginSessionID {
    static readonly type = "[sessionID] Login";

    constructor(public payload: any) {}
}

export class curationMemberRequest {
    static readonly type = "[curation member] Request";

    constructor() {}
}

export class curationMemberRequestSuccess {
    static readonly type = "[curation member] Success";

    constructor(public payload: any) {}
}

export class curationMemberRequestFail {
    static readonly type = "[curation member] Fail";

    constructor(public payload: any) {}
}

export class proposalRequest {
    static readonly type = "[proposal request] Request";

    constructor(public payload: any) {}
}

export class proposalRequestRequestSuccess {
    static readonly type = "[proposal request] Success";

    constructor() {}
}

export class proposalRequestRequestFail {
    static readonly type = "[proposal request] Fail";

    constructor() {}
}
