export function protocols(): string {
    return "/requests/protocol";
}

export function updateProtocols(id: any, type: any): string {
    return "/requests/protocol";
}

export function protocolType() {
    return "/dropdown/protocol_type";
}

export function protocolTypeId(id: any, type: any) {
    return "/requests/protocol/" + id + "/" + type;
}

export function events(id?: string) {
    return "/requests/event" + (id ? "/" + id : "");
}

export function eventRegistration(id: string, regID?: string) {
    return "/requests/event" + id + "/register" + (regID ? "/" + regID : "");
}

export function vacancy(id?: string) {
    return "/requests/vacancy" + (id ? "/" + id : "");
}

export function vacancyRegistration(id: string, regID?: string) {
    return (
        "/requests/vacancy" + id + "/registration" + (regID ? "/" + regID : "")
    );
}
