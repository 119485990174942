export class getProtocols {
    static readonly type = "[Protocols] Get";

    constructor() {}
}

export class getProtocolTypes {
    static readonly type = "[Protocols] Get Protcol Types";

    constructor() {}
}

export class getprotocolTypeId {
    static readonly type = "[Proto] Get";
    constructor(public id: any, public type: any) {}
}
export class getEvents {
    static readonly type = "[Events] Get";

    constructor() {}
}

export class getUserRequests {
    static readonly type = "[Requests] Get";

    constructor() {}
}

export class createProtocol {
    static readonly type = "[Protocols] create";

    constructor(public type: string, public payload: any) {}
}

export class updateProtocol {
    static readonly type = "[Protocols] update";

    constructor(public type: string, public payload: any) {}
}

export class HideQr {
    static readonly type = "[HideQr] protocol";

    constructor() {}
}

export class updateRequest {
    static readonly type = "[Requests] Update";
    constructor(public type: string, public id: string, public payload: any) {}
}

export class createEvent {
    static readonly type = "[Events] create";

    constructor(public type: string, public payload: any) {}
}

export class updateEvent {
    static readonly type = "[Events] Update";

    constructor(public type: string, public payload: any) {}
}

export class createVacancy {
    static readonly type = "[Vacancy] create";

    constructor(public type: string, public payload: any) {}
}

export class updateVacancy {
    static readonly type = "[Vacancy] Update";

    constructor(public type: string, public payload: any) {}
}

export class registerEvent {
    static readonly type = "[Event] Register";

    constructor(public id: string, public payload: any) {}
}

export class registerVacancy {
    static readonly type = "[Vacancy] Register";

    constructor(public id: string, public payload: any) {}
}

export class updateEventRegistration {
    static readonly type = "[Event] Register Update";

    constructor(
        public eventID: string,
        public id: string,
        public payload: any
    ) {}
}

export class updateVacancyRegistration {
    static readonly type = "[Vacancy] Register Update";

    constructor(
        public vacancyID: string,
        public id: string,
        public payload: any
    ) {}
}
