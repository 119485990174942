export class getEvents {
    static readonly type = "[Events] Get";
    constructor() {}
}

export class getEventsType {
    static readonly type = "[Events Type] Get";
    constructor() {}
}

export class getProprosals {
    static readonly type = "[Proposals] Get";
    constructor(public type: string) {}
}

export class createEvent {
    static readonly type = "[Events] create";
    constructor(public type: string, public payload: any) {}
}

export class updateEvent {
    static readonly type = "[Events] Update";
    constructor(public type: string, public payload: any) {}
}

export class registerEvent {
    static readonly type = "[Event] Register";
    constructor(public id: string, public payload: any) {}
}

export class updateEventRegistration {
    static readonly type = "[Event] Register Update";
    constructor(
        public eventID: string,
        public id: string,
        public payload: any
    ) {}
}

export class getActiveEvents {
    static readonly type = "[ActiveEvents] Get";
    constructor() {}
}
