import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "./shared.service";
@Injectable()
export class DataCompanyService {
    currentCompanyId = JSON.parse(localStorage.getItem("company_id"));
    currentSelectedRole;
    readonly currentRole: string;
    messageSource;
    currentMessageCompany;

    constructor(private sharedService: SharedService) {
        this.sharedService.getSelectedRoleData().subscribe((res) => {
            this.currentSelectedRole = res;
        });
        this.currentRole = this.currentSelectedRole
            ? this.currentSelectedRole.userSelectedCompany
            : null;
        this.messageSource = new BehaviorSubject(this.currentRole);
        this.currentMessageCompany = this.messageSource.asObservable();
    }
    changeMessageCompany(messageCompany: string) {
        this.messageSource.next(messageCompany);
    }
    clearMessagesCompany() {
        this.messageSource.next(null);
    }
}
