import { SharedService } from "app/services/shared.service";
// user.action.ts
import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { Registires } from "./registries.model";
import { ApiService } from "../../services/api.service";
import { getMasterData, saveMasterData } from "./registries.api";
import { getRegistires, saveRegistires } from "./registries.actions";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
@State<Registires>({
    name: "registries",
    defaults: {
        data: null,
    },
})
export class Registries {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}
    @Action(getRegistires)
    getRegistires(
        { getState, patchState }: StateContext<Registires>,
        { payload }: any
    ) {
        this.api
            .get(getMasterData(payload.name))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
    @Action(saveRegistires)
    saveRegistires(
        { getState, patchState }: StateContext<Registires>,
        { payload }: any
    ) {
        this.api
            .post(saveMasterData(payload.name), payload.data)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({ data: data.data });
            });
    }
}
