import { Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import Fortmatic from "fortmatic";

@Injectable({
    providedIn: "root",
})
export class SharedService {
    private selectedRoles: BehaviorSubject<any> = new BehaviorSubject<any>("");
    private navigations: BehaviorSubject<any> = new BehaviorSubject<any>("");

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private store: Store,
        private cookieService: CookieService
    ) {}

    setSelectedRoleData(value) {
        this.selectedRoles.next(value);
        localStorage.setItem("currentSelectedRole", value);
    }

    getSelectedRoleData() {
        if (!this.selectedRoles.value) {
            this.selectedRoles.next(
                localStorage.getItem("currentSelectedRole")
            );
        }
        return this.selectedRoles.asObservable();
    }

    setNavigationData(value) {
        this.navigations.next(value);
        localStorage.setItem("navigation", value);
    }

    getnavigationData() {
        if (!this.navigations.value) {
            this.navigations.next(localStorage.getItem("navigation"));
        }
        return this.navigations.asObservable();
    }

    onShowMessageBar(message) {
        this.snackBar.open(message, "Close", {
            duration: 6000,
            horizontalPosition: "center",
            verticalPosition: "top",
        });
    }

    onStatusCraterialCheck(param1, param2) {
        const params1 = { ...param1 };
        const params2 = { ...param2 };
        const paramsOne = { ...param1 };
        const paramsTwo = { ...param2 };
        delete params1["status"];
        delete params2["status"];

        if (JSON.stringify(param1) === JSON.stringify(param2)) {
            return false;
        } else if (
            param1.status !== param2["status"] &&
            JSON.stringify(params1) !== JSON.stringify(params2)
        ) {
            this.onShowMessageBar(
               // "Only comment can be updated while status update."
               "Other fields cannot be updated with Status."
            );
            return false;
        } else {
            for (let label in param1) {
                if (paramsOne[label] === paramsTwo[label]) {
                    delete paramsTwo[label];
                }
            }
            return paramsTwo;
        }
    }
 
    handleError(error) {
        switch (error.status) {
            case 400: {
                this.onShowMessageBar("Bad Request / Invalid Request");
                return;
            }
            case 401: {
                this.onShowMessageBar("Unauthorized");
                const fm = new Fortmatic("pk_live_DF44F7660C691718");
                fm.user.logout();
                this.store.reset({});
                localStorage.setItem("login", "");
                localStorage.setItem("user", "");
                var testObject = {
                    otp: null,
                    user: {},
                };
                localStorage.setItem("user", JSON.stringify(testObject));
                var loginObject = {
                    isLoggedIn: false,
                    token: "",
                };
                localStorage.setItem("login", JSON.stringify(loginObject));
                localStorage.clear();
                this.router.navigate(["/login"]);
                return;
            }
            case 403: {
                this.onShowMessageBar("Access Denied");
                return;
            }
            case 404: {
                this.onShowMessageBar("Api is not fould.");
                this.router.navigate(["/404"]);
                return;
            }
            case 405: {
                this.onShowMessageBar("Method Not Allowed");
                return;
            }
            case 409: {
                this.onShowMessageBar(
                    "The request could not be completed due to a conflict."
                );
                return;
            }
            case 500: {
                this.onShowMessageBar("Internal Server Error");
                this.router.navigate(["/500"]);
                return;
            }
            case 503: {
                this.onShowMessageBar("Service Unavailable");
                return;
            }
            default: {
                this.onShowMessageBar("Unknown Server Error");
                return;
            }
        }
    }
}
