import { SharedService } from "app/services/shared.service";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { Store } from "@ngxs/store";
import Fortmatic from "fortmatic";
import { Router } from "@angular/router";
import { LoginState } from "app/store/login/login.state";
import { UserStateModel } from "app/store/user/user.state";
import { NavigationComponent } from "app/navigation/navigation.component";
import { CookieService } from "ngx-cookie-service";
import { Navigate } from "@ngxs/router-plugin";
import { LoginFortmaticAction } from "app/store/login/login.actions";
import { VARIABLE_CONSTANT } from "@fuse/utils/constant";
import { ApiService } from "app/services/api.service";
@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent
    extends NavigationComponent
    implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: any;
    currentSelectedRole;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private store: Store,
        private router: Router,
        public sharedService: SharedService,
        private cookieService: CookieService,
        private api: ApiService,
    ) {
        super(sharedService);
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
        ];

        // this.navigation = this.navigation;
        this.sharedService.getnavigationData().subscribe((res) => {
            this.navigation = JSON.parse(res);
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        store
            .select((s) => s.user.user)
            .subscribe((u) => {
                if (u != "undefined" && u != null && u != " ") {
                    this.user = {
                        userName: u.userFirstName + " " + u.userLastName,
                        email: u.userEmailID,
                        pictureUrl: u.profilePicture,
                    };
                } else {
                    const getUserDetails = JSON.parse(
                        this.cookieService.get("user")
                    );
                    this.user = {
                        userName:
                            getUserDetails.userFirstName +
                            " " +
                            getUserDetails.userLastName,
                        email: getUserDetails.userEmailID,
                        pictureUrl: getUserDetails.profilePicture,
                    };
                    let fData = {
                        isLoggedIn: false,
                        token: null,
                        qrCode: null,
                        userID: getUserDetails.fortmatic_id,
                        userEmail: getUserDetails.userEmailID,
                        loginSessionID: null,
                    };
                    this.store.dispatch(new LoginFortmaticAction(fData));
                }
            });

        this.sharedService.getSelectedRoleData().subscribe((res) => {
            this.currentSelectedRole = res;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logoutUser(): void {
        this.cookieService.delete("token");
        this.cookieService.delete("userId");
        this.cookieService.delete("user");
        this.cookieService.delete("userKYC");
        this.cookieService.delete("email");
        this.cookieService.set(
            "token",
            "",
            VARIABLE_CONSTANT.expiryDate,
            VARIABLE_CONSTANT.defaultPath,
            VARIABLE_CONSTANT.DOMAIN
        );
        this.cookieService.set(
            "userId",
            "",
            VARIABLE_CONSTANT.expiryDate,
            VARIABLE_CONSTANT.defaultPath,
            VARIABLE_CONSTANT.DOMAIN
        );
        this.cookieService.set(
            "user",
            "",
            VARIABLE_CONSTANT.expiryDate,
            VARIABLE_CONSTANT.defaultPath,
            VARIABLE_CONSTANT.DOMAIN
        );
        this.cookieService.set(
            "userKYC",
            "",
            VARIABLE_CONSTANT.expiryDate,
            VARIABLE_CONSTANT.defaultPath,
            VARIABLE_CONSTANT.DOMAIN
        );
        this.cookieService.set(
            "email",
            "",
            VARIABLE_CONSTANT.expiryDate,
            VARIABLE_CONSTANT.defaultPath,
            VARIABLE_CONSTANT.DOMAIN
        );
        const fm = new Fortmatic("pk_live_DF44F7660C691718"); //pk_test_163E274EA5F264A2
        fm.user.logout();
        this.store.reset({});
        localStorage.setItem("user", "");
        localStorage.setItem("userId", "");
        localStorage.setItem("token", "");
        localStorage.setItem("userKYC", "");
        var testObject = {
            otp: null,
            user: {},
        };
        localStorage.setItem("user", JSON.stringify(testObject));
        var loginObject = {
            isLoggedIn: false,
            token: "",
        };
        localStorage.setItem("login", JSON.stringify(loginObject));
        this.sharedService.setSelectedRoleData("");
        localStorage.clear();
        this.api.logout().then((res) => {})
        this.router.navigate(["/login"]);
        this.store.dispatch(new Navigate(["/login"]));
    }
}
