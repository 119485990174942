import { SharedService } from "./../../services/shared.service";
import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";
import {
    LoginUportError,
    curationMemberRequest,
    ShowQr,
    SetAccessToken,
    SetLoginSessionID,
    LoginFortmaticAction,
} from "./login.actions";
import { Login } from "../login/login.model";
import { ApiService } from "../../services/api.service";
import { curationRequest } from "./login.api";
import { UpdateUser } from "../user/user.actions";
import { ShowSnackError } from "../snackbar/snackbar.actions";
import { ROUTES } from "app/main/shared/constants/routes.contant";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
@State<Login>({
    name: "login",
    defaults: {
        isLoggedIn: false,
        token: null,
        qrCode: null,
        loginSessionID: null,
        userID: null,
        userEmail: null,
    },
})
export class LoginState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService,
        private cookieService: CookieService
    ) {}
    ngxsOnInit(ctx: StateContext<Login>) {
        let state = ctx.getState();
        if (state.token)
            this.store.dispatch(new Navigate([ROUTES.CURATION_PROFILE]));
    }

    @Selector()
    static getToken(state: Login) {
        return state.token;
    }

    @Selector()
    static getSessionID(state: Login) {
        return state.loginSessionID;
    }

    @Action(SetLoginSessionID)
    setSessionID(
        { getState, patchState }: StateContext<Login>,
        { payload }: any
    ) {
        patchState({ loginSessionID: payload.sessionID });
    }

    @Action(LoginFortmaticAction)
    loginUserUport(
        { getState, patchState }: StateContext<Login>,
        { payload }: any
    ) {
        this.api.loginApiCall(payload);
    }

    @Action(curationMemberRequest)
    curationRequest({ getState, patchState }: StateContext<Login>) {
        let state = getState();
        this.api
            .get(curationRequest(state.loginSessionID))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({ qrCode: data.qrCode });
                this.store.dispatch(new ShowQr({ qrCode: data.qrCode }));
            });
    }

    @Action(SetAccessToken)
    setAccessToken(
        { getState, patchState }: StateContext<Login>,
        { payload }: any
    ) {
        if (!payload) return;
        patchState({ token: payload });
        // this.store.dispatch(new HideQr())
        if (payload.user) {
            this.store.dispatch(new UpdateUser(payload.user));
            this.store.dispatch(new Navigate([ROUTES.CURATION_PROFILE]));
        } else this.store.dispatch(new Navigate(["/complete-profile"]));
    }
}
