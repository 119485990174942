export class getRegistires {
    static readonly type = "[Registires Data Config] Get";

    constructor(public payload: any) {}
}

export class getRegistiresSuccess {
    static readonly type = "[Registires Data Config] success";

    constructor() {}
}

export class getRegistiresError {
    static readonly type = "[Registires Data Config] error";

    constructor() {}
}

export class saveRegistires {
    static readonly type = "[Registires Data ] Update";

    constructor(public payload: any) {}
}

export class saveRegistiresSuccess {
    static readonly type = "[Registires Data] Update success";

    constructor(payload: any) {}
}

export class saveRegistiresError {
    static readonly type = "[Registires Data] Update error";

    constructor(payload: any) {}
}
