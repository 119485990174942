export class getRequests {
    static readonly type = "[Requests] Get";
    constructor() {}
}

export class getRequestsType {
    static readonly type = "[Requests] Get";
    constructor(public type: string) {}
}

export class getPriority {
    static readonly type = "[Priority] Get";
    constructor() {}
}

export class getProcessor {
    static readonly type = "[Processor] Get";
    constructor() {}
}

export class getDashboard {
    static readonly type = "[Requests] Get";
    constructor(public type: string) {}
}

export class getEvents {
    static readonly type = "[Events] Get";
    constructor() {}
}
export class getVacancy {
    static readonly type = "[GetVacancy]";
    constructor(public id: any, public type: any, public payload: any) {}
}

export class getUserRequests {
    static readonly type = "[Requests] Get";
    constructor() {}
}

export class createRequest {
    static readonly type = "[Requests] create";
    constructor(public type: string, public payload: any) {}
}

export class updateRequest {
    static readonly type = "[Requests] Update";
    constructor(public type: string, public id: string, public payload: any) {}
}

export class createEvent {
    static readonly type = "[Events] create";
    constructor(public type: string, public payload: any) {}
}

export class updateEvent {
    static readonly type = "[Events] Update";
    constructor(public type: string, public payload: any) {}
}

export class createVacancy {
    static readonly type = "[Vacancy] create";
    constructor(public type: string, public payload: any) {}
}

export class updateVacancy {
    static readonly type = "[Vacancy] Update";
    constructor(public type: string, public payload: any) {}
}

export class registerEvent {
    static readonly type = "[Event] Register";
    constructor(public id: string, public payload: any) {}
}

export class registerVacancy {
    static readonly type = "[Vacancy] Register";
    constructor(public id: string, public payload: any) {}
}

export class updateEventRegistration {
    static readonly type = "[Event] Register Update";
    constructor(
        public eventID: string,
        public id: string,
        public payload: any
    ) {}
}

export class updateVacancyRegistration {
    static readonly type = "[Vacancy] Register Update";
    constructor(
        public vacancyID: string,
        public id: string,
        public payload: any
    ) {}
}
