// user.action.ts

import { State, Action, StateContext, Store } from "@ngxs/store";
import {
    EventsModel,
    EventsTypeStateModel,
    ProposalsModel,
} from "./events.model";
import { ApiService } from "../../services/api.service";
import { activeEvents, eventstype, proposals } from "./events.api";
import {
    getActiveEvents,
    getEventsType,
    getProprosals,
} from "./events.actions";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SharedService } from "app/services/shared.service";

@State<EventsTypeStateModel>({
    name: "eventsType",
    defaults: {
        types: null,
    },
})
export class EventsTypeState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getEventsType)
    getEventsType(
        { getState, patchState }: StateContext<EventsTypeStateModel>,
        { type }: any
    ) {
        this.api
            .get(eventstype())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    types: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<ProposalsModel>({
    name: "proposals",
    defaults: {
        data: null,
    },
})
export class ProposalsState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProprosals)
    getProprosals(
        { getState, patchState }: StateContext<ProposalsModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(proposals(type))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<EventsModel>({
    name: "activeEvents",
    defaults: {
        data: null,
    },
})
export class ActiveEventsState {
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getActiveEvents)
    getActiveEvents({
        getState,
        patchState,
    }: StateContext<ProposalsModel>): any {
        let requestsType;
        this.api
            .get(activeEvents())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}
