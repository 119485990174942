import { Component, Inject, OnDestroy, OnInit, Input } from "@angular/core";
import { Actions, Store, ofActionSuccessful } from "@ngxs/store";
import { Observable } from "rxjs";
@Component({
    selector: "headercomponent",
    template: `<div
        class="header p-24"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between end"
    >
        <div
            class="user-info"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center"
        ></div>
    </div>`,
    styles: [
        `
            .error {
                color: red;
            }
            .warn {
                color: orange;
            }
            .success {
                color: green;
            }
        `,
    ],
})
export class HeaderComponent {
    userName: string;

    constructor(private store: Store) {}

    ngOnInit() {
        this.store
            .select((s) => s.user.user)
            .subscribe((u) => {
                // if(u != 'undefined' && u != '' && u != 'null')
                // {
                //   this.userName = u.userFirstName+" "+u.userLastName;
                // }
            });
    }
}
