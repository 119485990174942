import * as moment from "moment";
declare var $: any;
export var masterConfig = [
    {
        configName: "Master Table",
        configID: "master_table",
        fields: [
            {
                name: "masterType",
                label: "Master Type",
                type: "select",
                editable: true,
                values: [
                    "SM",
                    "PRM",
                    "PTM",
                    "PYM",
                    "RTM",
                    "PSM",
                    "PS2M",
                    "DTM",
                    "UTM",
                    "ACM",
                    "RLM",
                    "BTY1",
                    "BTY2",
                    "CNT",
                    "LNDS",
                    "CMDIR",
                    "PARM",
                    "OBJM",
                    "TRTM",
                    "TRSSM",
                    "TRSM",
                    "TRSEM",
                    "SFS",
                ],
            },
            {
                name: "masterID",
                label: "Master ID",
                type: "text",
                editable: true,
            },
            {
                name: "text",
                label: "Text",
                type: "text",
                editable: true,
            },
            {
                name: "shortText",
                label: "Short Text",
                type: "text",
                editable: true,
            },
            {
                name: "longText",
                label: "Long Text",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
                editable: false,
            },
            {
                name: "timeStamp",
                label: "Time Stamp",
                type: "date",
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
        ],
    },
    {
        configName: "Request Status Life Cycle",
        configID: "request_status_life_cycle",
    },
    {
        configName: "Request Vacancy Link",
        configID: "request_vacancy_link",
    },
    {
        configName: "Request Object Link",
        configID: "request_object_link",
    },
    {
        configName: "Request Event Link",
        configID: "request_event_link",
    },
    {
        configName: "Request Protocol Link",
        configID: "request_protocol_link",
    },
    {
        configName: "Voting Status",
        configID: "voting_status",
    },
    {
        configName: "Entity Type Master",
        configID: "entity_type_master",
        fields: [
            {
                name: "entityType",
                label: "Entity Type",
                type: "text",
                editable: true,
            },
            {
                name: "fungible",
                label: "Fungible",
                type: "select",
                values: ["true", "false"],
                editable: true,
            },
            {
                name: "description",
                label: "Description",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "domain",
                label: "Domain",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Domain Master",
        configID: "domian_master",
        fields: [
            {
                name: "domainName",
                label: "Domain",
                type: "text",
                editable: true,
            },
            {
                name: "description",
                label: "Description",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Process Type Master",
        configID: "process_type_master",
        fields: [
            {
                name: "protocolCommand",
                label: "Protocol Command",
                type: "text",
                editable: true,
            },
            {
                name: "processTypeName",
                label: "Name",
                type: "text",
                editable: true,
            },
            {
                name: "processTypeDescription",
                label: "Description",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "domain",
                label: "Domain",
                type: "text",
                editable: true,
            },
            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Characteristic Type Master",
        configID: "characteristic_type_master",
        fields: [
            {
                name: "characteristName",
                label: "name",
                type: "text",
                editable: true,
            },
            {
                name: "Characteristic Description",
                label: "description",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "associatedCharacteristics",
                label: "Associated Charactristics",
                type: "text",
                editable: true,
            },
            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Relationship Type Master",
        configID: "relationship_type_master",
        fields: [
            {
                name: "relationshipType",
                label: "Relationship Type",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipTypeDescription",
                label: "Relationship Type Description",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },

            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Entity Process Master",
        configID: "entity_process_master",
        fields: [
            {
                name: "entityTypeID",
                label: "Entity Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "processTypeID",
                label: "Process Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipDirection",
                label: "Relationship Direction",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipID",
                label: "Realtionship ID",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },

            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Entity Process Char Master",
        configID: "entity_process_char_master",
        fields: [
            {
                name: "entityTypeID",
                label: "Entity Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "processTypeID",
                label: "Process Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipDirection",
                label: "Relationship Direction",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipID",
                label: "Realtionship ID",
                type: "text",
                editable: true,
            },
            {
                name: "characteristicTypeID",
                label: "Characteristic Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "characteristicRelationshipDirection",
                label: "Characteristic Relationship Direction",
                type: "text",
                editable: true,
            },
            {
                name: "characteristicRelationshipID",
                label: "Characteristic Relationship ID",
                type: "text",
                editable: true,
            },
            {
                name: "domain",
                label: "Domain",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },

            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Sponsorhip Price Master",
        configID: "sponsorship_price_master",
        fields: [
            {
                name: "sponsorshipTypeID",
                label: "Sponsorship Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "entitysTypeID",
                label: "Entity Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "sponsorshipPrice",
                label: "Sponsor Direction",
                type: "text",
                editable: true,
            },
            {
                name: "realtionshipID",
                label: "Realtionship ID",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },

            {
                name: "requestID",
                label: "Request ID",
                type: "text",
            },
            {
                name: "proposalID",
                label: "Proposal ID",
                type: "text",
            },
        ],
    },
    {
        configName: "Ad Type Timeline",
        configID: "ad_type_timeline",
        fields: [
            {
                name: "adTypeID",
                label: "Ad Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "entitysTypeID",
                label: "Entity Type ID",
                type: "text",
                editable: true,
            },
            {
                name: "adPrice",
                label: "Sponsor Direction",
                type: "text",
                editable: true,
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
            {
                name: "currency",
                label: "Currency",
                type: "text",
            },
        ],
    },
    {
        configName: "XPost Frequency Master",
        configID: "xpost_frequency_master",
        fields: [
            {
                name: "frequency",
                label: "Frequency",
                type: "text",
            },
        ],
    },
    {
        configName: "Static Parameters Master",
        configID: "static_parameters_master",
        fields: [
            {
                name: "parameterType",
                label: "Parameter Type",
                type: "text",
            },
            {
                name: "parameterName",
                label: "Parameter Name",
                type: "text",
            },
            {
                name: "parameterDescription",
                label: "Parameter Description",
                type: "text",
            },
            {
                name: "ruleFileID",
                label: "Rule File ID",
                type: "text",
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
        ],
    },
    {
        configName: "User Request Type",
        configID: "user_request_type",
        fields: [
            {
                name: "requestType",
                label: "Request Type",
                type: "text",
            },
            {
                name: "Application",
                label: "application",
                type: "text",
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
        ],
    },
    {
        configName: "Master Type",
        configID: "master_type",
        fields: [
            {
                name: "masterType",
                label: "Master Type",
                type: "text",
            },
            {
                name: "masterText",
                label: "Master Text",
                type: "text",
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
        ],
    },
    {
        configName: "Master Table Role",
        configID: "master_table_role",
        fields: [
            {
                name: "masterType",
                label: "Master Type",
                type: "text",
            },
            {
                name: "masterTable",
                label: "MAster Table",
                type: "text",
            },
            {
                name: "masterText",
                label: "Master Text",
                type: "text",
            },
            {
                name: "ruleFileID",
                label: "Rule File ID",
                type: "text",
            },
            {
                name: "createdBy",
                label: "Created By",
                type: "text",
            },
            {
                name: "fromDate",
                label: "From",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "toDate",
                label: "To",
                type: "date",
                editable: true,
                formatter: (s) => {
                    return moment(s).format("DD/MM/YYYY");
                },
            },
            {
                name: "status",
                label: "Status",
                type: "select",
                editable: true,
                values: ["ACTIVE", "INACTIVE"],
            },
        ],
    },
    {
        configName: "Marketplace Request Transaction Types",
        configID: "marketplace_request_transaction_types",
    },
];

export const components = {
    datepicker: function getDatePicker() {
        function Datepicker() {}
        Datepicker.prototype.init = function (params) {
            this.eInput = document.createElement("input");
            this.eInput.value = params.value;
            $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
        };
        Datepicker.prototype.getGui = function () {
            return this.eInput;
        };
        Datepicker.prototype.afterGuiAttached = function () {
            this.eInput.focus();
            this.eInput.select();
        };
        Datepicker.prototype.getValue = function () {
            return this.eInput.value;
        };
        Datepicker.prototype.destroy = function () {};
        Datepicker.prototype.isPopup = function () {
            return false;
        };
        return Datepicker;
    },
};

export const DateTimeformat = (value) => {
    let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };
    let myDate = new Date(parseInt(value));
    let dateTime = moment(myDate).format("DD MMM YYYY HH:MM:SS");
    return dateTime.toLocaleUpperCase();
};

export const Dateformat = (value) => {
    let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };
    let myDate = new Date(parseInt(value));
    let dateTime = moment(myDate).format("DD MMM YYYY");
    return dateTime.toLocaleUpperCase();
};

export type requestFields = {
    text: string;
    requestType: string;
    statusOptions?: Array<String>;
    status?: string;
    eventID?: string;
};

export const grievianceRequestTypes: Array<requestFields> = [
    {
        requestType: "USR_GVC",
        text: "User Grevience",
    },
    {
        requestType: "USR_PRP",
        text: "User Proposals",
    },
    {
        requestType: "USR_REQ",
        text: "User Requests",
    },
];

export const vacancyTypes: Array<requestFields> = [
    {
        requestType: "GCM",
        text: "Vacancy Type1",
    },
    {
        requestType: "CCM",
        text: "Vacancy Type2",
    },
    {
        requestType: "GCM",
        text: "Vacancy Type3",
    },
    {
        requestType: "CCM",
        text: "Vacancy Type3",
    },
];

export const protocolTypes: Array<requestFields> = [
    {
        requestType: "VC_PRT",
        text: "VC_PRT",
    },
];

export const eventTypes: Array<requestFields> = [
    {
        requestType: "CBB",
        text: "Contribution - Bug bounty",
    },
    {
        requestType: "CMD",
        text: "Contribution - ML Development",
    },
    {
        requestType: "CRD",
        text: "Contribution - Reports Development",
    },
    {
        requestType: "CAD",
        text: "Contribution - Analytics Development",
    },
    {
        requestType: "CTR",
        text: "Contribution - Training",
    },
    {
        requestType: "CTS",
        text: "Contribution - Testing",
    },
    {
        requestType: "CPS",
        text: "Contribution - Proposal and suggestions",
    },
    {
        requestType: "IPS",
        text: "Investment - Private Sale",
    },
    {
        requestType: "IPIC",
        text: "Investment - Pre ICO",
    },
    {
        requestType: "IICP1",
        text: "Investment - Phase1",
    },
    {
        requestType: "IICP2",
        text: "Investment - Phase2",
    },
    {
        requestType: "IICP3",
        text: "Investment - Phase3",
    },
    {
        requestType: "IICP4",
        text: "Investment - Phase4",
    },
    {
        requestType: "INIC",
        text: "Investment - Non ICO",
    },
    {
        requestType: "SMDM",
        text: "Service - Master data maintenenace",
    },
    {
        requestType: "SRQA",
        text: "Service - Requests addresal",
    },
    {
        requestType: "SVAL",
        text: "Service - Validations",
    },
    {
        requestType: "ADRP",
        text: "Air Drop",
    },
];

export const requestTypes: Array<requestFields> = [
    {
        requestType: "GC_AR",
        text: "GC Activation Request",
    },
    {
        requestType: "CC_MR",
        text: "CC Member Request",
    },
    {
        requestType: "RQ_KYC",
        text: "KYC Validator Request",
    },
    {
        requestType: "RQ_EAR",
        text: "Escrow Arbiter Request",
    },
    {
        requestType: "RQ_VCR",
        text: "Vault Curator Request",
    },
    {
        requestType: "RQ_UER",
        text: "User Entity Realtionship Validation Request",
    },
    {
        requestType: "RQ_DMC",
        text: "Domain Creation Request",
    },
    {
        requestType: "RQ_ETC",
        text: "Entity Type Creation Request",
    },
    {
        requestType: "RQ_PTC",
        text: "Process Type Creation Request",
    },
    {
        requestType: "RQ_CTC",
        text: "Characteristic Type Creation Request",
    },
    {
        requestType: "RQ_RTC",
        text: "Realtionship Type Creation Request",
    },
    {
        requestType: "USR_GVC",
        text: "User Grevience",
    },
    {
        requestType: "USR_PRP",
        text: "User Proposals",
    },
    {
        requestType: "USR_REQ",
        text: "User Requests",
    },
    {
        requestType: "EVT_PR",
        text: "Event Participation Request",
    },
    {
        requestType: "EVT_PP",
        text: "Event Participation Payout Request",
    },
    {
        requestType: "EVT_CR",
        text: "Event Creation Request",
    },
    {
        requestType: "VCC_CR",
        text: "Vacancy Creation Request",
    },
    {
        requestType: "PRT_CR",
        text: "Protocol Creation Request",
    },
    {
        requestType: "USSR_PR",
        text: "User Parent Validation",
    },
    {
        requestType: "USSR_AR",
        text: "User Parent Validation",
    },
];
