import {
    Component,
    ViewChild,
    Input,
    EventEmitter,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import {
    HttpClientModule,
    HttpClient,
    HttpRequest,
    HttpResponse,
    HttpEventType,
} from "@angular/common/http";
import { ApiService } from "../../../services/api.service";
import { environment } from "environments/environment";
@Component({
    selector: "file-upload",
    templateUrl: "./file-upload-component.html",
    styleUrls: ["./file-upload-component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent {
    percentDone: number;
    uploadSuccess: boolean;
    @ViewChild("input") input: any;
    @Input("text") text: string;
    @Input("key") key: string;
    @Output() onFileUploaded = new EventEmitter();

    constructor(private http: HttpClient, private api: ApiService) {}

    chooseFile(e: any) {
        e.preventDefault();
        this.input.nativeElement.click();
    }

    upload(files: File[]) {
        //pick from one of the 4 styles of file uploads below
        this.uploadAndProgressSingle(files);
    }

    //this will fail since file.io dosen't accept this type of upload
    //but it is still possible to upload a file with this style
    uploadAndProgressSingle(file: any) {
        var formData = new FormData();
        formData.append("file", file);
        this.onFileUploaded.emit(file);
    }
}
