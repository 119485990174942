export const locale = {
    lang: "tr",
    data: {
        NAV: {
            APPLICATIONS: "Programlar",
            SAMPLE: {
                TITLE: "Örnek",
                BADGE: "15",
            },
            MANAGE_BOTS: {
                TITLE: "Manage Bots",
                BADGE: "30",
            },
        },
    },
};
