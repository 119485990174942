import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FuseDirectivesModule } from "@fuse/directives/directives";
import { FusePipesModule } from "@fuse/pipes/pipes.module";
import { HeaderComponent } from "../app/main/shared/components/header.component";
import { FileUploadComponent } from "../app/main/shared/components/file-upload-component";
import { MaterialImportsModule } from "app/main/shared/material-imports/material-imports/material-imports.module";

@NgModule({
    declarations: [HeaderComponent, FileUploadComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        MaterialImportsModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HeaderComponent,
        FileUploadComponent,
        FuseDirectivesModule,
        FusePipesModule,
        MaterialImportsModule,
    ],
})
export class FuseSharedModule {}
