import { typeSourceSpan } from "@angular/compiler";
export function requests(): string {
    return "/requests/all-requests/";
}

export function priorities(): string {
    return "/dropdown/priority";
}

export function requestsdashboard(type: string) {
    return "/requests/dashboard/" + type;
}
export function requeststype(type): string {
    return "/requests/all-requests/" + type;
}
export function userRequests(): string {
    return "/requests/get-user-requests/";
}
export function events(id?: string) {
    return "/requests/event" + (id ? "/" + id : "");
}
export function eventRegistration(id: string, regID?: string) {
    return "/requests/event/" + id + "/register" + (regID ? "/" + regID : "");
}
export function vacancy(id?: string) {
    return "/requests/vacancy" + (id ? "/" + id : "");
}
export function vacancyget(id: any, type: any) {
    return "/requests/vacancy/" + id + "/" + type;
}

export function prosessorData(text: any) {
    return "/requests/filter/processor/" + text + "/" + "dashboard";
}

export function vacancyRegistration(id: string, regID?: string) {
    return (
        "/requests/vacancy" + id + "/registration" + (regID ? "/" + regID : "")
    );
}
