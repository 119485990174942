// user.action.ts

import { State, Action, StateContext, Store } from "@ngxs/store";
import { ApiService } from "../../services/api.service";
import { getProposalDetails } from "./proposal.actions";
import { proposalDetails } from "./proposal.api";
import { ProposalModel } from "./proposal.model";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SharedService } from "app/services/shared.service";

@State<ProposalModel>({
    name: "proposal",
    defaults: {
        data: null,
    },
})
export class ProposalState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProposalDetails)
    getProposalDetails(
        { getState, patchState }: StateContext<ProposalModel>,
        { id, type }: any
    ) {
        this.api
            .get(proposalDetails(id, type))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: data.data,
                });
            });
    }
}
