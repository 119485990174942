export class getVacancies {
    static readonly type = "[Vacancies] Get";
    constructor() {}
}

export class getVacancyType {
    static readonly type = "[Vacancy Types] Get";
    constructor() {}
}

export class createVacancy {
    static readonly type = "[Vacancies] create";
    constructor(public type: string, public payload: any) {}
}

export class actionVacancy {
    static readonly type = "[Vacancies] action";
    constructor(public type: string, public payload: any) {}
}

export class updateVacancy {
    static readonly type = "[Vacancy] Update";
    constructor(public type: string, public payload: any) {}
}

export class registerVacancy {
    static readonly type = "[Vacancy] Register";
    constructor(public id: string, public payload: any) {}
}

export class updateVacancyRegistration {
    static readonly type = "[Vacancy] Register Update";
    constructor(
        public vacancyID: string,
        public id: string,
        public payload: any
    ) {}
}

export class HideQr {
    static readonly type = "[HideQr] protocol";
    constructor() {}
}

export class getActiveVacancies {
    static readonly type = "[ActiveVacancies] Get";
    constructor() {}
}
