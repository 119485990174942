import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { userLoginComponent } from "./userLogin.component";
import { requestDialog } from "./dialogs/request-dialog";
import {
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
} from "@angular/material";
import { LoginLoaderComponent } from "./login-loader/login-loader.component";
import { AccessCodeComponent } from "./access-code/access-code.component";
import { AuthGuardService } from "./../../services/auth.guard.service";

const routes = [
    {
        path: "login",
        component: userLoginComponent,
        canActivate: [AuthGuardService],
    },
];

@NgModule({
    declarations: [
        userLoginComponent,
        requestDialog,
        LoginLoaderComponent,
        AccessCodeComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
    exports: [userLoginComponent, requestDialog],
    entryComponents: [requestDialog, AccessCodeComponent],
})
export class userLoginModule {}
