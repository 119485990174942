import { SharedService } from "app/services/shared.service";
// user.action.ts

import { State, Action, StateContext, Store } from "@ngxs/store";
import {
    ProtocolsModel,
    MyProtocol,
    ProtocolsTypesStateModel,
} from "./protocols.model";
import { ApiService } from "../../services/api.service";
import { protocols, protocolType, protocolTypeId } from "./protocols.api";
import {
    ShowSnackError,
    ShowSnackbarSuccess,
} from "../snackbar/snackbar.actions";
import {
    getProtocols,
    getProtocolTypes,
    getprotocolTypeId,
    HideQr,
    createProtocol,
    updateProtocol,
    updateRequest,
    createEvent,
} from "./protocols.actions";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@State<ProtocolsModel>({
    name: "protocols",
    defaults: {
        data: null,
    },
})
export class ProtocolsState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProtocols)
    getProtocols(
        { getState, patchState }: StateContext<ProtocolsModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(protocols())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(getprotocolTypeId)
    getprotocolTypeId(
        { getState, patchState }: StateContext<MyProtocol>,
        { id, type }: any
    ) {
        let requestsType;
        this.api
            .get(protocolTypeId(id, type))
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: data.data,
                });
            });
    }

    @Action(createProtocol)
    createProtocol(
        { getState, patchState }: StateContext<ProtocolsModel>,
        { type, id, payload }: any
    ) {
        let data12 = {
            version: payload.protocolVersion,
            protocolName: payload.protocolName,
            protocolType: payload.protocolName,
            description: payload.description,
            endpoint: "aa",
            supportingFileID: "5f6d9f2902c48821c07d84c6",
            KYCFileID: "5f6d9f2902c48821c07d84c6",
            KYCFlag: false,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
            statusUpdateResponsibleID: "5f6d9f2902c48821c07d84c6",
            requestID: "5f6d9f2902c48821c07d84c6",
        };

        this.api.protocols(data12).then((data: any) => {
            // patchState({
            //     data: {
            //         records: data.data
            //     }
            // });
            this.store.dispatch(new HideQr());
            switch (data.success) {
                case true: {
                    this.store.dispatch(
                        new ShowSnackbarSuccess({
                            type: "SUCCESS",
                            message: data.msg,
                        })
                    );
                }
                case false: {
                    this.store.dispatch(
                        new ShowSnackError({ type: "ERROR", message: data.msg })
                    );
                }
            }
        });
    }

    @Action(updateRequest)
    updateRequest(
        { getState, patchState }: StateContext<ProtocolsModel>,
        { type, id, payload }: any
    ) {
        // this.api.post(protocols(type), payload)
        //     .toPromise()
        //     .then((data: any) => {
        //         patchState({
        //             data: {
        //                 records: data.data
        //             }
        //         });
        //     })
    }

    @Action(updateProtocol)
    updateProtocol(
        { getState, patchState }: StateContext<ProtocolsModel>,
        { type, id, payload }: any
    ) {
        // let data12 = {
        //     version: payload.protocolVersion,
        //     protocolName: payload.protocolName,
        //     protocolType: payload.protocolName,
        //     description: payload.description,
        //     endpoint: "aa",
        //     supportingFileID: "5f6d9f2902c48821c07d84c6",
        //     KYCFileID: "5f6d9f2902c48821c07d84c6",
        //     KYCFlag: false,
        //     fromDate: payload.fromDate,
        //     toDate: payload.toDate,
        //     statusUpdateResponsibleID: "5f6d9f2902c48821c07d84c6",
        //     requestID: "5f6d9f2902c48821c07d84c6"
        // }
        // this.api.protocols(data12).then((data: any) => {
        //     // patchState({
        //     //     data: {
        //     //         records: data.data
        //     //     }
        //     // });
        //     this.store.dispatch(new HideQr())
        //     switch (data.success) {
        //         case true: {
        //             this.store.dispatch(new ShowSnackbarSuccess({ type: "SUCCESS", message: data.msg }))
        //         }
        //         case false: {
        //             this.store.dispatch(new ShowSnackError({ type: "ERROR", message: data.msg }))
        //         }
        //     }
        // })
    }

    @Action(createEvent)
    createEvent(
        { getState, patchState }: StateContext<ProtocolsModel>,
        { type, id, payload }: any
    ) {
        // this.api.post(requests(id), payload)
        //     .toPromise()
        //     .then((data: any) => {
        //         patchState({
        //             data: {
        //                 records: data.data
        //             }
        //         });
        //     })

        this.api.events(payload).then((data: any) => {
            patchState({
                data: {
                    records: data,
                },
            });
            switch (data.success) {
                case true: {
                    this.store.dispatch(
                        new ShowSnackbarSuccess({
                            type: "SUCCESS",
                            message: data.msg,
                        })
                    );
                }
                case false: {
                    this.store.dispatch(
                        new ShowSnackError({ type: "ERROR", message: data.msg })
                    );
                }
            }
        });
    }
}
@State<ProtocolsTypesStateModel>({
    name: "protocolsTypes",
    defaults: {
        typeData: null,
    },
})
export class ProtocolsTypesState {
    socket: any;
    constructor(private store: Store, private api: ApiService) {}

    @Action(getProtocolTypes)
    getProtocolTypes(
        { getState, patchState }: StateContext<ProtocolsTypesStateModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(protocolType())
            .toPromise()
            .then((data: any) => {
                patchState({
                    typeData: {
                        records: data.data,
                    },
                });
            });
    }
}
