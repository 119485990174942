export function registerMasterData(): string {
    return "/registries/master_table";
}

export function registerMasterTypeData(): string {
    return "/dropdown/master_type";
}

export function registerAdTypeData(): string {
    return "/dropdown/ad_type";
}

export function registerRequestStatusLifeCycleData(): string {
    return "/registries/request_status_lifecycle_master";
}

export function registerRequestTypeData(): string {
    return "/dropdown/request_type";
}

export function userRequestTypeListData(): string {
    return "/dropdown/user_request_type";
}

export function registerRequestVacancyLinkData(): string {
    return "/registries/request_vacancy_link";
}

export function registerRequestObjectLinkData(): string {
    return "/registries/request_object_link";
}

export function registerRequestEventLinkData(): string {
    return "/registries/request_event_link";
}

export function registerRequestProtocolLinkData(): string {
    return "/registries/request_protocol_link";
}

export function registerVotingStatusData(): string {
    return "/registries/voting_status";
}

export function registerEntityTypeMasterData(): string {
    return "/registries/entity_type_master";
}

export function registerDomainMasterData(): string {
    return "/registries/domain_master";
}

export function registerProcessTypeMasterData(): string {
    return "/registries/process_type_master";
}

export function registerCharacteristicTypeMasterData(): string {
    return "/registries/characteristic_type_master";
}

export function registerRelationshipTypeMasterData(): string {
    return "/registries/relationship_type_master";
}

export function registerEntityProcessMasterData(): string {
    return "/registries/entity_process_master";
}

export function registerEntityProcessCharMasterData(): string {
    return "/registries/entity_process_char_master";
}

export function registerSponsorshipMasterData(): string {
    return "/registries/sponsorship_master";
}

export function registerAdTypeTimelineData(): string {
    return "/registries/ad_type_timeline";
}

export function registerXpostFrequencyMasterData(): string {
    return "/registries/xpost_frequency_master";
}

export function registerStatisticParametersMasterData(): string {
    return "/registries/statistic_parameters_master";
}

export function GCRoleData(): string {
    return "/dropdown/gc_role";
}

export function CCRoleData(): string {
    return "/dropdown/cc_role";
}

export function userRoleData(): string {
    return "/dropdown/user_role";
}

export function vacancyTypeData(): string {
    return "/dropdown/vacancy_type";
}

export function roleData(): string {
    return "/dropdown/role";
}

export function objectNameData(): string {
    return "/dropdown/object_name";
}

export function statData(): string {
    return "/dropdown/stat";
}

export function eventTypeData(): string {
    return "/dropdown/event_type";
}

export function domainMasterData(): string {
    return "/dropdown/domain_master";
}

export function protocolTypeData(): string {
    return "/dropdown/protocol_type";
}

export function associatedProcessesData(): string {
    return "/dropdown/process_type_master";
}

export function associatedCharacteristicsData(): string {
    return "/dropdown/characteristic_type_master";
}

export function entityTypeData(): string {
    return "/dropdown/entity_type_master";
}

export function processTypeData(): string {
    return "/dropdown/process_type_master";
}

export function relationshipDirectionData(): string {
    return "/dropdown/relationship_direction";
}

export function relationshipTypeData(): string {
    return "/dropdown/relationship_type";
}

export function characteristicTypeData(): string {
    return "/dropdown/characteristic_type_master";
}

export function sponsorshipTypeData(): string {
    return "/dropdown/sponsorship_type";
}

export function objectTypeData(): string {
    return "/dropdown/object_type";
}

export function parameterTypeData(): string {
    return "/dropdown/parameter_type";
}

export function priorityTypeData(): string {
    return "/dropdown/priority";
}

export function userRequestTypeData(): string {
    return "/registries/user_request_type";
}

export function masterTypeData(): string {
    return "/registries/master_type";
}

export function masterTableRoleData(): string {
    return "/registries/master_table_role";
}

export function marketplaceRequestTransactionTypeData(): string {
    return "/registries/marketplace_request_transaction_types";
}

export function requestTypeData(): string {
    return "/dropdown/marketplace_request_type";
}

export function transactionTypeData(): string {
    return "/dropdown/marketplace_transaction_type";
}
