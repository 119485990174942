import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-login-loader",
    templateUrl: "./login-loader.component.html",
    styleUrls: ["./login-loader.component.scss"],
})
export class LoginLoaderComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
