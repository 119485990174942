export class ROUTES {
    public static CURATION_MODULE = "curation";
    public static GOVERNANCE_MODULE = "governance";
    public static REGISTERIES_MODULE = "registries";
    public static PROFILE_MODULE = "profile";
    public static PROTOCOL_MODULE = "protocol";
    public static REQUEST_MODULE = "requests";
    public static MANAGE_REQUEST_MODULE = "manage-requests";
    public static EVENT_MODULE = "event";
    public static VACANCY_MODULE = "vacancy";
    public static CURATION_PROFILE = "curation/profile";
    public static GOVERNANCE_PROFILE = "governance/profile";
    public static CURATION_REQUEST = "curation/requests";
    public static VACANCIES_MODULE = "vacancies";
    public static EVENTS_MODULE = "events";
    public static USER_PROFILE_MODULE = "user";
    public static USER_LOGIN_MODULE = "user/dashboard";
}
