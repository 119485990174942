import { SharedService } from "app/services/shared.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Navigate } from "@ngxs/router-plugin";
import { LoginUportError, SetAccessToken } from "app/store/login/login.actions";
import { UpdateUser } from "app/store/user/user.actions";
import { VARIABLE_CONSTANT } from "@fuse/utils/constant";
const SERVER_URL = environment.baseUrl;
@Injectable({
    providedIn: "root",
})
export class ApiService {
    constructor(
        private http: HttpClient,
        private store: Store,
        private sharedService: SharedService,
        private cookieService: CookieService
    ) {}
    httpHeaderOptions: any = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        }),
    };
    generateHeaders(options?: any): HttpHeaders {
        var httpHeaders = null;
        var token = this.cookieService.get("token");

        if (token) {
            httpHeaders = new HttpHeaders({
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            });
        } else {
            httpHeaders = new HttpHeaders({
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
            });
        }
        return httpHeaders;
    }

    generateHeadersFormData(options?: any): HttpHeaders {
        var httpHeaders = null;
        var token = this.cookieService.get("token");
        if (token) {
            httpHeaders = new HttpHeaders({
                Authorization: `Bearer ${token}`,
            });
        }

        return httpHeaders;
    }

    loginFortmatic(data): Promise<any> {
        let userData = {
            fortmatic_id: data.userID,
            userEmailID: data.userEmail,
        };
        let rolePath = "";
        rolePath = "/user-auth/configLogin";
        return this.http
            .post(SERVER_URL + rolePath, userData, this.httpHeaderOptions)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }
    logout() {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
          .get(environment.baseUrl + "/user-auth/logout",{ headers: headers })
          .pipe(catchError((e) => throwError(this.sharedService.handleError(e))))
          .toPromise();
      } 
    requests(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
            .post(SERVER_URL + "/requests", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }
    requestVote(id, type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/requests/vote/" + id + "/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    protocols(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/requests/protocol", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    registerMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/master_table", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateMasterTable(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(`${SERVER_URL + "/registries/master_table"}/${id}`, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addMasterType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/master_type", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateMasterType(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(`${SERVER_URL + "/registries/master_type"}/${id}`, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRequestStatusLifeCycle(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_status_lifecycle", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequestStatusLifeCycle(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_status_lifecycle"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRequestVacancyType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_vacancy_link", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequestVacancy(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_vacancy_link"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRequestObjectLinkType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_object_link", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequestObjectLinkType(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_object_link"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRequestProtocolLinkType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_protocol_link", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequestProtocolLinkType(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_protocol_link"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRequestVotingStatus(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/voting_status", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequestVotingStatus(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(`${SERVER_URL + "/registries/voting_status"}/${id}`, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    events(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/requests/event", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    registerEvent(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        let data12 = {
            description: data.description,
            eventType: data.eventType,
            protocolID: "5f6d9f2902c48821c07d84c6",
            requestID: "5f6d9f2902c48821c07d84c6",
            payout: "abc12",
            startTimestamp: data.fromDate,
            endTimestamp: data.toDate,
            comment: data.comment,
        };
        return this.http
            .post(SERVER_URL + "/requests/event/" + id + "/register", data12, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }
    UserPeerMembers(gcFlag, ccFlag): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                SERVER_URL +
                    "/user/peer-members/" +
                    gcFlag +
                    "/" +
                    ccFlag +
                    " ",
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getRequest(ids): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/" + ids, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getRSLC(type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/registries/request_status_lifecycle/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getRSLCEligible(requestType, status, sequence, dashboard): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                `${SERVER_URL}/registries/request_status_lifecycle_eligible/${requestType}/${status}/${sequence}/${dashboard}`,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    UserMemberShip(type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/user/membership-status/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    vacancies(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/requests/vacancy", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    adVacany(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
            .post(SERVER_URL + "/requests/vacancy", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    showVacancy(): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/vacancy", { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    showEvent(): Promise<any> {
        // show events on GC
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/event", { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    adEvents(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
            .post(SERVER_URL + "/requests/event", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    showProtocol(): Promise<any> {
        // show events on GC
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/protocol", { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    adProtocols(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
            .post(SERVER_URL + "/requests/protocol", data, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    get(urlAddress: string, options?: any): Observable<any> {
        const headers: HttpHeaders = this.generateHeaders(options);
        return this.http
            .get<any>(SERVER_URL + urlAddress, { headers: headers })
            .pipe(map((data) => data));
    }

    post(urlAddress: string, payload?: any, options?: any): Observable<any> {
        const headers: HttpHeaders = this.generateHeaders(options);
        return this.http
            .post<any>(SERVER_URL + urlAddress, payload, { headers: headers })
            .pipe(map((data) => data));
    }

    put(urlAddress: string, payload: any, options?: any): any {
        const headers: HttpHeaders = this.generateHeaders(options);
        return this.http
            .put<any>(SERVER_URL + urlAddress, payload, { headers: headers })
            .pipe(map((data) => data));
    }

    delete(urlAddress: string, options?: any): any {
        const headers: HttpHeaders = this.generateHeaders(options);
        return this.http
            .delete<any>(SERVER_URL + urlAddress)
            .pipe(map((data) => data));
    }

    sendGetFileRequest(url): any {
        let headers = new HttpHeaders();
        headers = headers.append("Accept", "text/csv; charset=utf-8");
        return this.http.get(url, {
            headers: headers,
            observe: "response",
            responseType: "text",
        });
    }

    approvalList(id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/approvals/" + id, { headers: headers })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getRequestbyDashboard(id, skip, limit): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                SERVER_URL +
                    "/requests/dashboard/" +
                    id +
                    "/" +
                    skip +
                    "/" +
                    limit,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getManageRequestApi(skip, limit) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                `${
                    SERVER_URL + "/requests/get-user-requests"
                }/${skip}/${limit}`,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getvacancyApplyRequestApi(id) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/vacancy/apply"}/${id}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getEventApplyRequestApi(id) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/event/apply"}/${id}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getCompleteRequestApi(id) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/complete-request"}/${id}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getWithdrawRequestApi(id) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/withdraw-request"}/${id}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getPayoutRequestApi(id) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/request-payout"}/${id}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getProtocolByTypeId(id, type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/protocol/" + id + "/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getvacancyById(id, type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/vacancy/" + id + "/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getEventById(id, type): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(SERVER_URL + "/requests/event/" + id + "/" + type, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    fileUpload(id, type, data): Promise<any> {
        const headers: HttpHeaders = this.generateHeadersFormData();
        return this.http
            .post(
                SERVER_URL + "/requests/upload-artifact/" + id + "/" + type,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateProtocols(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(SERVER_URL + "/requests/protocol/" + id, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateEvents(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(SERVER_URL + "/requests/event/" + id, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRequest(dashboardType, data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                SERVER_URL +
                    "/requests/update-request/" +
                    id +
                    "/" +
                    dashboardType,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    search(term) {
        const headers: HttpHeaders = this.generateHeaders();
        var listOfProposals = this.http
            .get(SERVER_URL + "/requests/filter/proposal/" + term, {
                headers: headers,
            })
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length != 0
                        ? (data.data as any[])
                        : [{ ProposalName: "No Record Found" } as any];
                })
            );

        return listOfProposals;
    }

    searchProcessor(term, type) {
        const headers: HttpHeaders = this.generateHeaders();
        var listOfProcessor = this.http
            .get(
                SERVER_URL + "/requests/filter/processor/" + term + "/" + type,
                { headers: headers }
            )
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length != 0
                        ? (data.data as any[])
                        : [{ ProcessorName: "No Record Found" } as any];
                })
            );

        return listOfProcessor;
    }

    searchProtocol(term) {
        const headers: HttpHeaders = this.generateHeaders();
        var listOfProtocol = this.http
            .get(SERVER_URL + "/requests/filter/protocol/" + term, {
                headers: headers,
            })
            .pipe(
                debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
                map((data: any) => {
                    return data.length != 0
                        ? (data.data as any[])
                        : [{ ProtcolName: "No Record Found" } as any];
                })
            );

        return listOfProtocol;
    }

    updateVacancies(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(SERVER_URL + "/requests/vacancy/" + id, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addEventTypeMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/entity_type_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateEventTypeMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/entity_type_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addDomainMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/domain_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateDomainMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(`${SERVER_URL + "/registries/domain_master"}/${id}`, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addProcessTypeMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/process_type_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateProcessTypeMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/process_type_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addCharacticsticTypeMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/characteristic_type_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateCharacticsticTypeMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${
                    SERVER_URL + "/registries/characteristic_type_master"
                }/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    relationshipTypeList(param1, param2): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                SERVER_URL +
                    `${"/dropdown/relationship_master"}/${param1}/${param2}`,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addRelationshipTypeMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/relationship_type_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateRelationshipTypeMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/relationship_type_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addEntityProcessMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/entity_process_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateEntityProcessMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/entity_process_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addEntityProcessCharMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/entity_process_char_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateEntityProcessCharMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${
                    SERVER_URL + "/registries/entity_process_char_master"
                }/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addSponsorshipMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/sponsorship_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateSponsorshipMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/sponsorship_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addAdTypeTimelineMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/ad_type_timeline", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateAdTypeTimelineMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(`${SERVER_URL + "/registries/ad_type_timeline"}/${id}`, data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addXPostFrequencyMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/xpost_frequency_master", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateXPostFrequencyMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/xpost_frequency_master"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addEventLinkMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_event_link", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateEventLinkMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_event_link"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addProtocolLinkMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/request_protocol_link", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateProtocolLinkMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/request_protocol_link"}/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addStaticsticParametersMaster(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                SERVER_URL + "/registries/statistic_parameters_master",
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateStaticsticParametersMaster(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${
                    SERVER_URL + "/registries/statistic_parameters_master"
                }/${id}`,
                data,
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addMasterTableRole(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/master_table_role", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateMasterTableRole(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/master_table_role"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addMarketPlaceRequestTransactionType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                SERVER_URL +
                    "/registries/marketplace_request_transaction_types",
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateMarketPlaceRequestTransactionType(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${
                    SERVER_URL +
                    "/registries/marketplace_request_transaction_types"
                }/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    addUserRequestType(data): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(SERVER_URL + "/registries/user_request_type", data, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    updateUserRequestType(data, id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .put(
                `${SERVER_URL + "/registries/user_request_type"}/${id}`,
                data,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getActiveEventApi() {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(`${SERVER_URL + "/requests/active-events"}`, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getActiveVacanciesApi() {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(`${SERVER_URL + "/requests/active-vacancies"}`, {
                headers: headers,
            })
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    getArtifactApi(params) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .get(
                `${SERVER_URL + "/requests/get-artifact"}/${params.requestID}/${
                    params.artifactId
                }/${params.type}`,
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    deleteArtifactApi(params) {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL + "/requests/delete-artifact"}/${
                    params.requestID
                }/${params.artifactId}/${params.type}`,
                {},
                {
                    headers: headers,
                }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    approvalsApi(id, vote): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL}/requests/vote/${id}/${vote}`,
                {},
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    approvalsListApi(id): Promise<any> {
        const headers: HttpHeaders = this.generateHeaders();
        return this.http
            .post(
                `${SERVER_URL}/requests/approvals/${id}`,
                {},
                { headers: headers }
            )
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise();
    }

    accessCodeApi(data): Observable<any> {
        return this.http.post(environment.baseUrl + "/access-code", data).pipe(
            catchError((error) => {
                this.sharedService.handleError(error);
                return throwError(error.message || error);
            })
        );
    }

    loginApiCall(payload) {
        this.loginFortmatic(payload).then((response: any) => {
            if (response.success) {
                this.store.dispatch(new SetAccessToken(response.data.token));
                this.store.dispatch(new UpdateUser(response.data.user));
                this.store.dispatch(new Navigate(["/user/dashboard"]));
                this.cookieService.set(
                    "token",
                    response.data.token,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                );
                this.cookieService.set(
                    "user",
                    JSON.stringify(response.data.user),
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                );
                this.cookieService.set(
                    "userId",
                    response.data.user._id,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                );
                this.cookieService.set(
                    "email",
                    response.data.user.userEmailID,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                );
                this.cookieService.set(
                    "userKYC",
                    response.data.user.userKYCFlag,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                );
            } else {
                this.sharedService.onShowMessageBar(response.msg);
                this.store.dispatch(new LoginUportError());
            }
        });
    }
}
