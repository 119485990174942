import { SharedService } from "app/services/shared.service";
// user.action.ts
import { State, Action, StateContext, Store } from "@ngxs/store";
import { RequestsModel, PriorityModel, ProcessorModel } from "./requests.model";
import { ApiService } from "../../services/api.service";
import {
    requests,
    vacancyget,
    requestsdashboard,
    events,
    eventRegistration,
    priorities,
    prosessorData,
    userRequests,
} from "./requests.api";
import {
    ShowSnackError,
    ShowSnackbarSuccess,
} from "../snackbar/snackbar.actions";
import {
    getVacancy,
    getDashboard,
    getEvents,
    getUserRequests,
    createRequest,
    updateRequest,
    createEvent,
    registerEvent,
    getPriority,
    getProcessor,
} from "./requests.actions";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@State<RequestsModel>({
    name: "requests",
    defaults: {
        data: null,
    },
})
export class RequestsState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getDashboard)
    getDashboard(
        { getState, patchState }: StateContext<RequestsModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(requestsdashboard(type))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(getUserRequests)
    getUserRequests(
        { getState, patchState }: StateContext<RequestsModel>,
        { type }: any
    ) {
        let requestsType;
    }

    @Action(createRequest)
    createRequest(
        { getState, patchState }: StateContext<RequestsModel>,
        { type, id, payload }: any
    ) {
        this.api.requests(payload).then((data: any) => {
            patchState({
                data: {
                    records: data.data,
                },
            });
            switch (data.success) {
                case true: {
                    this.store.dispatch(
                        new ShowSnackbarSuccess({
                            type: "SUCCESS",
                            message: data.msg,
                        })
                    );
                }
                case false: {
                    this.store.dispatch(
                        new ShowSnackError({ type: "ERROR", message: data.msg })
                    );
                }
            }
        });
    }
    @Action(getVacancy)
    getVacancy(
        { getState, patchState }: StateContext<RequestsModel>,
        { id, type, payload }: any
    ) {
        this.api
            .get(vacancyget(id, type), payload)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(updateRequest)
    updateRequest(
        { getState, patchState }: StateContext<RequestsModel>,
        { type, id, payload }: any
    ) {
        this.api
            .post(requests(), payload)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(registerEvent)
    registerEvent(
        { getState, patchState }: StateContext<RequestsModel>,
        { type, id, payload }: any
    ) {
        this.api
            .post(eventRegistration(id), payload)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(createEvent)
    createEvent(
        { getState, patchState }: StateContext<RequestsModel>,
        { type, id, payload }: any
    ) {
        let data12 = {
            description: payload.description,
            eventType: payload.eventType,
            protocolID: "5f6d9f2902c48821c07d84c6",
            requestID: "5f6d9f2902c48821c07d84c6",
            payout: "abc12",
            startTimestamp: payload.fromDate,
            endTimestamp: payload.toDate,
            comment: payload.comment,
        };
        this.api.events(data12).then((data: any) => {});
    }

    @Action(getEvents)
    getEvents(
        { getState, patchState }: StateContext<RequestsModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(events())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<PriorityModel>({
    name: "priority",
    defaults: {
        data: null,
    },
})
export class PriorityState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getPriority)
    getPriority(
        { getState, patchState }: StateContext<PriorityModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(priorities())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<ProcessorModel>({
    name: "processor",
    defaults: {
        data: null,
    },
})
export class ProcessorState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getProcessor)
    getProcessor(
        { getState, patchState }: StateContext<ProcessorModel>,
        { text }: any
    ) {
        let requestsType;
        this.api
            .get(prosessorData(text))
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<ProcessorModel>({
    name: "userRequest",
    defaults: {
        data: null,
    },
})
export class UserRequestState {
    socket: any;
    constructor(
        private api: ApiService,
        private sharedService: SharedService
    ) {}

    @Action(getUserRequests)
    getUserRequests({ getState, patchState }: StateContext<ProcessorModel>) {
        this.api
            .get(userRequests())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}
