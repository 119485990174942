export function updateProfile(): string {
    return "/user/profile/";
}

export function getFileById(id: string): string {
    return "/file/" + id;
}

export function getImageByID(id: string): string {
    return "/file/image/" + id;
}

export function updateKyc(): string {
    return "/user/profile/kyc";
}

export function requestOTP(): string {
    return "/user/otp/request";
}

export function verifyOTPRequest(): string {
    return "/user/otp/verify";
}
