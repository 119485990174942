import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatTooltipModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCheckboxModule,
} from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
    declarations: [],

    imports: [
        CommonModule,
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatInputModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        MatTooltipModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatCheckboxModule,
    ],
    exports: [
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatInputModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        MatTooltipModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatCheckboxModule,
    ],
})
export class MaterialImportsModule {}
