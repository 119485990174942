export const locale = {
    lang: "en",
    data: {
        NAV: {
            APPLICATIONS: "Applications",
            DASHBOARD: {
                TITLE: "DashBoard",
                BADGE: "25",
            },
            PROFILE: {
                TITLE: "Profile",
                BADGE: "25",
            },
            USERPROFILE: {
                TITLE: "Profile",
                BADGE: "25",
            },
            REGISTRIES: {
                TITLE: "Registries",
                BADGE: "30",
            },
            REQUEST: {
                TITLE: "Request",
                BADGE: "30",
            },
            KYC: {
                TITLE: "KYC",
                BADGE: "30",
            },
            VAULT: {
                TITLE: "Vault",
                BADGE: "30",
            },
            ESCROW: {
                TITLE: "Escrow",
                BADGE: "30",
            },
            EPCREQUESTS: {
                TITLE: "EPC Requests",
                BADGE: "30",
            },
            RELATIONSHIPVALIDATION: {
                TITLE: "Requests",
                BADGE: "30",
            },
            PROTOCOL: {
                TITLE: "Protocol",
                BADGE: "30",
            },
            VACANCY: {
                TITLE: "Vacancy",
                BADGE: "30",
            },
            EVENT: {
                TITLE: "Event",
                BADGE: "30",
            },
            CCAPPROVALVALIDATION: {
                TITLE: "CC Approval Validation",
                BADGE: "30",
            },
            GCMEMBERREQUEST: {
                TITLE: "GC Member Request",
            },
            COUNCIL: {
                TITLE: "Curation Council Member Request",
                BADGE: "30",
            },
            REQUESTS: {
                TITLE: "Requests",
                BADGE: "30",
            },
        },
    },
};
