import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material";
import { grievianceRequestTypes } from "app/utils/masterConfig";
import { Store } from "@ngxs/store";
import { createRequest } from "app/store/requests/requests.actions";
import { environment } from "environments/environment";
import { getImageByID } from "app/store/user/user.api";

@Component({
    selector: "request-dialog",
    templateUrl: "./request-dialog.html",
    styles: [
        `
            form {
                width: 400px;
            }
        `,
    ],
})
export class requestDialog {
    requestForm: FormGroup;
    artifacts: Array<any>;
    priorityList: Array<string> = ["low", "medium", "high"];
    requestTypes: Array<any> = grievianceRequestTypes;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _form: FormBuilder,
        private store: Store
    ) {
        (this.artifacts = []),
            (this.requestForm = this._form.group({
                description: ["", Validators.required],
                requestType: ["", Validators.required],
                priority: ["", Validators.required],
            }));
    }

    submitForm() {
        let request = this.requestForm.value;
        request.artifacts = this.artifacts;
        let requestType = request.requestType;
        this.store.dispatch(new createRequest(requestType, request));
    }

    insertArtifactID(id) {
        this.artifacts.push({
            id: id,
            url: environment.baseUrl + getImageByID(id),
        });
    }
}
