import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";
import { SharedService } from "./services/shared.service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_SNACK_BAR_DATA } from "@angular/material";
import { Actions, Store, ofActionSuccessful } from "@ngxs/store";
import { Observable } from "rxjs";
import { User } from "./store/user/user.model";
import { NavigationComponent } from "./navigation/navigation.component";
import { LoginFortmaticAction } from './store/login/login.actions';
@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent
    extends NavigationComponent
    implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    memberType: any;
    snackbarMessage$: Observable<string[]>;
    user$: Observable<any>;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private snackbar: MatSnackBar,
        private router: Router,
        private store: Store,
        public sharedService: SharedService,
        private cookieService: CookieService
    ) {
        super(sharedService);
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        //const getUserDetails = this.cookieService.get("user");
        // if(getUserDetails) {
        //     const userDetails = JSON.parse(getUserDetails);
        //     let fData = {
        //         isLoggedIn: false,
        //         token: null,
        //         qrCode: null,
        //         userID: userDetails.fortmatic_id,
        //         userEmail: userDetails.userEmailID,
        //         loginSessionID: null,
        //     };
        //     this.store.dispatch(new LoginFortmaticAction(fData));
        // }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.snackbarMessage$ = this.store.select((state) => state.snackbar);
        this.user$ = this.store.select((state) => state.user.user);
        this.user$.subscribe((user: any) => {
            if (user && user.ccFlag) {
                this.memberType = user.ccRole;
            }

            if (user && user.gcFlag) {
                this.memberType = user.gcRole;
            }

            if (user && !user.gcFlag && !user.ccFlag) {
                this.memberType = "user";
            }

            this.memberType = "user";

            if (user) this.registerMenu(user);
        });
    }

    registerMenu(user) {
        this.sharedService.getnavigationData().subscribe((res) => {
            this.navigation = JSON.parse(res);
        });

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation, user);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["en", "tr"]);

        // Set the default language
        this._translateService.setDefaultLang("en");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationTurkish
        );

        // Use a language
        this._translateService.use("en");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        // listen to snack bar
        this.snackbarMessage$.subscribe((snack: any) => {
            if (snack) this.showSnackBar(snack.type, snack.message);
        });
    }

    /**
     * On destroy
     */

    showSnackBar(type: string, message: string) {
        // this.snackbar.openFromComponent(SnackbarComponent, {
        //     data: { type: type, message: message },
        //     duration: 3000,
        // });
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}

// snackbar components

@Component({
    selector: "snackbar",
    template: `<span
        [ngClass]="{
            error: data.type == 'ERROR',
            warn: data.type == 'WARNING',
            success: data.type == 'SUCCESS'
        }"
    >
        {{ data.type }}: {{ data.message }}
    </span>`,
    styles: [
        `
            .error {
                color: red;
            }
            .warn {
                color: orange;
            }
            .success {
                color: green;
            }
        `,
    ],
})
export class SnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
