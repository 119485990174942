import { User } from "./user.model";

export class SetUser {
    static readonly type = "[User] Set Store";

    constructor(public payload: User) {}
}
export class UpdateUser {
    static readonly type = "[User] Update";

    constructor(public payload: User) {}
}

export class ClearUser {
    static readonly type = "[User] Clear User on Logout";
}
