export class getMasterData {
    static readonly type = "[Master Data] Get";
    constructor() {}
}

export class getRegisterMasterData {
    static readonly type = "[RegisterMasterData] Get";
    constructor() {}
}

export class getRegisterMasterTypeData {
    static readonly type = "[RegisterMasterTypeData] Get";
    constructor() {}
}
export class getRegisterMasterAdTypeData {
    static readonly type = "[RegisterAdTypeData] Get";
    constructor() {}
}

export class getRegisterRequestStatusLifeCycleData {
    static readonly type = "[RegisterRequestStatusLifeCycleData] Get";
    constructor() {}
}

export class getRegisterRequestTypeData {
    static readonly type = "[RegisterRequestTypeData] Get";
    constructor() {}
}

export class getUserRequestTypeListData {
    static readonly type = "[UserRequestTypeListData] Get";
    constructor() {}
}

export class getGCRolesData {
    static readonly type = "[GCRolesData] Get";
    constructor() {}
}

export class getCCRolesData {
    static readonly type = "[CCRolesData] Get";
    constructor() {}
}

export class getUserRolesData {
    static readonly type = "[UserRolesData] Get";
    constructor() {}
}

export class getRolesData {
    static readonly type = "[RolesData] Get";
    constructor() {}
}

export class getVacancyTypeData {
    static readonly type = "[VacancyTypeData] Get";
    constructor() {}
}

export class getObjectNameData {
    static readonly type = "[ObjectNameData] Get";
    constructor() {}
}

export class getStatData {
    static readonly type = "[ObjectNameData] Get";
    constructor() {}
}

export class getEventTypeData {
    static readonly type = "[EventTypeData] Get";
    constructor() {}
}

export class getDomainMasterData {
    static readonly type = "[DomainMasterData] Get";
    constructor() {}
}

export class getProtocolTypeData {
    static readonly type = "[ProtocolTypeData] Get";
    constructor() {}
}

export class getAssociatedProcessesData {
    static readonly type = "[AssociatedProcessesData] Get";
    constructor() {}
}

export class getAssociatedCharacteristicsData {
    static readonly type = "[AssociatedCharacteristicsData] Get";
    constructor() {}
}

export class getEntityTypeData {
    static readonly type = "[EntityTypeData] Get";
    constructor() {}
}

export class getProcessTypeData {
    static readonly type = "[ProcessTypeData] Get";
    constructor() {}
}

export class getRelationshipTypeData {
    static readonly type = "[RelationshipIDData] Get";
    constructor() {}
}

export class getRelationshipDirectionData {
    static readonly type = "[RelationshipDirectionData] Get";
    constructor() {}
}

export class getCharacteristicTypeData {
    static readonly type = "[CharacteristicTypeData] Get";
    constructor() {}
}

export class getSponsorshipTypeData {
    static readonly type = "[SponsorshipTypeData] Get";
    constructor() {}
}

export class getObjectTypeData {
    static readonly type = "[ObjectTypeData] Get";
    constructor() {}
}

export class getParameterTypeData {
    static readonly type = "[ParameterTypeData] Get";
    constructor() {}
}

export class getPriorityTypeData {
    static readonly type = "[PriorityTypeData] Get";
    constructor() {}
}

export class getMasterDataSuccess {
    static readonly type = "[Master Data] success";
    constructor(payload: any) {}
}

export class getMasterDataError {
    static readonly type = "[Master Data] error";

    constructor(payload: any) {}
}

export class saveMasterData {
    static readonly type = "[Master Data] Get";

    constructor(payload: any) {}
}

export class saveMasterDataSuccess {
    static readonly type = "[Master Data] Save success";

    constructor(payload: any) {}
}

export class saveMasterDataError {
    static readonly type = "[Master Data] Save error";

    constructor(payload: any) {}
}

export class createMaster {
    static readonly type = "[Masters] create";
    constructor(public type, public payload: any) {}
}

export class getRegisterRequestVacancyLinkData {
    static readonly type = "[RegisterRequestVacancyLinkData] Get";
    constructor() {}
}

export class getRegisterRequestObjectLinkData {
    static readonly type = "[RegisterRequestObjectLinkData] Get";
    constructor() {}
}

export class getRegisterRequestEventLinkData {
    static readonly type = "[RegisterRequestEventLinkData] Get";
    constructor() {}
}

export class getRegisterRequestProtocolLinkData {
    static readonly type = "[RegisterRequestProtocolLinkData] Get";
    constructor() {}
}

export class getRegisterVotingStatusData {
    static readonly type = "[RegisterVotingStatusData] Get";
    constructor() {}
}

export class getRegisterEntityTypeMasterData {
    static readonly type = "[RegisterEntityTypeMasterData] Get";
    constructor() {}
}

export class getRegisterDomainMasterData {
    static readonly type = "[RegisterDomainMasterData] Get";
    constructor() {}
}

export class getRegisterProcessTypeMasterData {
    static readonly type = "[RegisterProcessTypeMasterData] Get";
    constructor() {}
}

export class getRegisterCharacteristicTypeMasterData {
    static readonly type = "[RegisterCharacteristicTypeMasterData] Get";
    constructor() {}
}

export class getRegisterRelationshipTypeMasterData {
    static readonly type = "[RegisterRelationshipTypeMasterData] Get";
    constructor() {}
}

export class getRegisterEntityProcessMasterData {
    static readonly type = "[RegisterEntityProcessMasterData] Get";
    constructor() {}
}

export class getRegisterEntityProcessCharMasterData {
    static readonly type = "[RegisterEntityProcessCharMasterData] Get";
    constructor() {}
}

export class getRegisterSponsorshipMasterData {
    static readonly type = "[RegisterSponsorshipMasterData] Get";
    constructor() {}
}

export class getRegisterAdTypeTimelineData {
    static readonly type = "[RegisterAdTypeTimelineData] Get";
    constructor() {}
}

export class getRegisterXpostFrequencyMasterData {
    static readonly type = "[RegisterXpostFrequencyMasterData] Get";
    constructor() {}
}

export class getRegisterStatisticParametersMasterData {
    static readonly type = "[RegisterStatisticParametersMasterData] Get";
    constructor() {}
}

export class getUserRequestTypeMasterData {
    static readonly type = "[UserRequestTypeMasterData] Get";
    constructor() {}
}

export class getMasterTypeData {
    static readonly type = "[MasterTypeData] Get";
    constructor() {}
}

export class getMasterTableRoleData {
    static readonly type = "[getMasterTableRoleData] Get";
    constructor() {}
}

export class getMarketplaceRequestTransactionTypeData {
    static readonly type = "[MarketplaceRequestTransactionTypeData] Get";
    constructor() {}
}

export class getRequestTypeData {
    static readonly type = "[RequestTypeData] Get";
    constructor() {}
}

export class getTransactionTypeData {
    static readonly type = "[TransactionTypeData] Get";
    constructor() {}
}
