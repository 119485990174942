import { ApiService } from "app/services/api.service";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";
import { ROUTES } from "app/main/shared/constants";

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private store: Store,
        private cookieService: CookieService,
        private router: Router,
        private apiService: ApiService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        let activateRoute = true;
        if (
            this.cookieService.get("token") &&
            JSON.parse(this.cookieService.get("userKYC"))
        ) {
            if (state.url !== "/login") {
                activateRoute = true;
            } else if (state.url == "/login") {
                this.router.navigate(["/user/dashboard"]);
                activateRoute = false;
            }
        } else if (state.url !== "/login") {
            this.router.navigate(["/login"]);
            activateRoute = false;
        }
        return activateRoute;
    }
}
