// user.action.ts
import { State, Action, StateContext, Store } from "@ngxs/store";
import { VacancyModel, VacancyTypesModel } from "./vacancy.model";
import { ApiService } from "../../services/api.service";
import {
    vacancyRegistration,
    vacancies,
    vacanciesTypes,
    activeVacancies,
} from "./vacancy.api";
import {
    ShowSnackError,
    ShowSnackbarSuccess,
} from "../snackbar/snackbar.actions";
import {
    getVacancies,
    HideQr,
    actionVacancy,
    createVacancy,
    updateVacancy,
    registerVacancy,
    getVacancyType,
    getActiveVacancies,
} from "./vacancy.actions";
import { SharedService } from "app/services/shared.service";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@State<VacancyModel>({
    name: "vacancies",
    defaults: {
        data: null,
    },
})
export class VacancyState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}
    @Action(getVacancies)
    getVacancies(
        { getState, patchState }: StateContext<VacancyModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(vacancies())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }

    @Action(createVacancy)
    createVacancy(
        { getState, patchState }: StateContext<VacancyModel>,
        { type, id, payload }: any
    ) {
        let data12 = {
            vacancyType: payload.requestType,
            role: payload.roleID,
            openVacancy: "aaa",
            openFromDate: payload.fromDate,
            openToDate: payload.toDate,
            requestID: "5f6d9f2902c48821c07d84c6",
        };

        this.api.vacancies(data12).then((data: any) => {
            this.store.dispatch(new HideQr());
            switch (data.success) {
                case true: {
                    this.store.dispatch(
                        new ShowSnackbarSuccess({
                            type: "SUCCESS",
                            message: data.msg,
                        })
                    );
                }
                case false: {
                    this.store.dispatch(
                        new ShowSnackError({ type: "ERROR", message: data.msg })
                    );
                }
            }
        });
    }
    @Action(updateVacancy)
    updateVacancy(
        { getState, patchState }: StateContext<VacancyModel>,
        { type, id, payload }: any
    ) {}

    @Action(actionVacancy)
    actionVacancy(
        { getState, patchState }: StateContext<VacancyModel>,
        { type, id, payload }: any
    ) {}
    @Action(registerVacancy)
    registerVacancy(
        { getState, patchState }: StateContext<VacancyModel>,
        { id, payload }: any
    ) {
        this.api
            .post(vacancyRegistration(id), payload)
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<VacancyTypesModel>({
    name: "vacancyTypes",
    defaults: {
        data: null,
    },
})
export class VacancyTypesState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}
    @Action(getVacancyType)
    getVacancyType(
        { getState, patchState }: StateContext<VacancyTypesModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(vacanciesTypes())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}

@State<VacancyModel>({
    name: "activeVacancies",
    defaults: {
        data: null,
    },
})
export class ActiveVacancyState {
    socket: any;
    constructor(
        private store: Store,
        private api: ApiService,
        private sharedService: SharedService
    ) {}
    @Action(getActiveVacancies)
    getActiveVacancies(
        { getState, patchState }: StateContext<VacancyModel>,
        { type }: any
    ) {
        let requestsType;
        this.api
            .get(activeVacancies())
            .pipe(
                catchError((e) => throwError(this.sharedService.handleError(e)))
            )
            .toPromise()
            .then((data: any) => {
                patchState({
                    data: {
                        records: data.data,
                    },
                });
            });
    }
}
