import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: "app-not-found-data",
    templateUrl: "./not-found-data.component.html",
    styleUrls: ["./not-found-data.component.scss"],
})
export class NotFoundDataComponent implements OnInit {
    dataGet: any;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<NotFoundDataComponent>
    ) {}

    ngOnInit() {
        this.dataGet = this.data.name;
    }

    closeEventDialog() {
        this.dialogRef.close();
    }
}
