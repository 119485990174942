import { SharedService } from "../services/shared.service";
import { Component, OnInit } from "@angular/core";
import { FuseNavigation } from "@fuse/types";
import { ROUTES } from "app/main/shared/constants/routes.contant";

@Component({
    selector: "navogation",
    template: "",
    styles: [""],
})
export class NavigationComponent implements OnInit {
    currentSelectedRole;
    childrens: FuseNavigation[] = [];
    navigation: FuseNavigation[];

    constructor(public sharedService: SharedService) {
        this.sharedService.getSelectedRoleData().subscribe((res) => {
            this.currentSelectedRole = res;
            this.onLoadRoles();
        });
    }

    ngOnInit(): void {
        this.onLoadRoles();
    }

    onLoadRoles() {
        this.childrens = [];
        if (this.currentSelectedRole === "GC") {
            this.childrens.push(
                {
                    id: "profile",
                    title: "Profile",
                    translate: "Profile",
                    type: "item",
                    url: ROUTES.GOVERNANCE_PROFILE,
                    roles: ["gc", "cc"],
                },
                {
                    id: "requests",
                    title: "Requests",
                    translate: "Requests",
                    type: "item",
                    url: "governance/requests",
                    roles: ["gc", "cc"],
                },
                {
                    id: "prtocol",
                    title: "Protocol",
                    translate: "Protocol",
                    type: "item",
                    url: "governance/protocol",
                    roles: ["gc"],
                },
                {
                    id: "vacancy",
                    title: "Vacancy",
                    translate: "Vacancy",
                    type: "item",
                    url: "governance/vacancy",
                    roles: ["gc"],
                },
                {
                    id: "event",
                    title: "Event",
                    translate: "Event",
                    type: "item",
                    url: "governance/event",
                    roles: ["gc"],
                }
            );
        }
        if (this.currentSelectedRole == "CC") {
            this.childrens.push(
                {
                    id: "profile",
                    title: "Profile",
                    translate: "Profile",
                    type: "item",
                    url: ROUTES.CURATION_PROFILE,
                    roles: ["cc"],
                },
                {
                    id: "registries",
                    title: "Registries",
                    translate: "Registries",
                    type: "item",
                    url: "curation/registries",
                    roles: ["cc"],
                },
                {
                    id: "request",
                    title: "Request",
                    translate: "Request",
                    type: "item",
                    url: ROUTES.CURATION_REQUEST,
                    roles: ["cc"],
                }
            );
        }
        this.childrens.unshift({
            id: "dashboard",
            title: "DashBoard",
            translate: "DashBoard",
            type: "item",
            url: "user/dashboard",
            roles: ["cc", "gc"],
        });
        this.navigation = [
            {
                id: "applications",
                title: "",
                translate: "",
                type: "group",
                roles: ["gc", "cc"],
                children: this.childrens,
            },
        ];
        this.sharedService.setNavigationData(JSON.stringify(this.navigation));
    }
}
