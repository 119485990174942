import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { fuseConfig } from "app/fuse-config";
import { AppComponent, SnackbarComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { HeaderComponent } from "./main/shared/components/header.component";
import { LoginModule } from "app/main/login-module/login.module";
import { userLoginModule } from "app/main/userLogin-module/userLogin.module";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { LoginState } from "./store/login/login.state";
import { UserState } from "./store/user/user.state";
import {
    ProtocolsState,
    ProtocolsTypesState,
} from "./store/protocols/protocols.state";
import {
    AssociatedCharacteristicsMasterState,
    AssociatedProcessesMasterState,
    CCRolesState,
    CharacteristicTypeDataState,
    DomainMaster,
    EntityTypeMasterState,
    EventTypeState,
    GCRolesState,
    MarketplaceRequestTransactionTypeDataState,
    MasterDataState,
    MasterTableRoleDataState,
    MasterTypeState,
    ObjectNameState,
    ObjectTypeMasterState,
    ParameterTypeMasterState,
    PriorityTypeMasterState,
    ProcessTypeMasterState,
    ProtocolTypeMasterState,
    RegisterAdTypeDataState,
    RegisterAdTypeTimelineState,
    RegisterCharacteristicTypeMasterState,
    RegisterDomainMasterState,
    RegisterEntityProcessCharMasterState,
    RegisterEntityProcessMasterState,
    RegisterEntityTypeMasterState,
    RegisterMasterState,
    RegisterMasterTypeState,
    RegisterProcessTypeMasterState,
    RegisterRelationshipTypeMasterState,
    RegisterRequestEventLinkState,
    RegisterRequestObjectLinkState,
    RegisterRequestProtocolLinkState,
    RegisterRequestStatusLifeCycleState,
    RegisterRequestTypeState,
    RegisterSponsorshipMasterState,
    RegisterStatisticParametersMasterState,
    RegisterVotingStatusState,
    RegisterXpostFrequencyMasterState,
    RelationshipDirectionMasterState,
    RelationshipTypeMasterState,
    RequestTypeDataState,
    RequestVacancyLinkState,
    RolesState,
    SponsorshipTypeMasterState,
    StatState,
    TransactionTypeDataState,
    UserRequestTypeListState,
    UserRequestTypeMasterState,
    UserRolesState,
    VacancyTypeState,
} from "./store/masterData/master.data.state";
import { Registries } from "./store/registries/registries.state";
import { SnackbarState } from "./store/snackbar/snackbar.state";
import {
    RequestsState,
    PriorityState,
    UserRequestState,
} from "./store/requests/requests.state";
import {
    ActiveVacancyState,
    VacancyState,
    VacancyTypesState,
} from "./store/vacancy/vacancy.state";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
// shared services
import { ApiService } from "./services/api.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./services/auth.interceptor";
import { AuthGuardService } from "./services/auth.guard.service";
import { ProposalDetailsDialog } from "./main/governance-council-module/modules/governance-requests/dialogs/proposal-details-dialog";
import { ConfirmationDialogComponent } from "./main/shared/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogService } from "./main/shared/confirmation-dialog/confirmation-dialog.service";
import { DataCompanyService } from "./services/role-navigation.service";
import { NotFoundDataComponent } from "./main/shared/components/not-found-data/not-found-data.component";
import { AppRoutingModule } from "./app-routing.module";
import { CommonModule } from "@angular/common";
import {
    ActiveEventsState,
    EventsTypeState,
    ProposalsState,
} from "./store/events/events.state";
import { SharedService } from "./services/shared.service";
import { NavigationComponent } from "./navigation/navigation.component";
import { ProposalState } from "./store/proposal/proposal.state";
import { Page404Component } from "./main/shared/components/page404/page404.component";
import { Page500Component } from "./main/shared/components/page500/page500.component";
@NgModule({
    declarations: [
        AppComponent,
        SnackbarComponent,
        ProposalDetailsDialog,
        ConfirmationDialogComponent,
        NotFoundDataComponent,
        Page404Component,
        Page500Component,
        NavigationComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        userLoginModule,
        NgxsModule.forRoot([
            LoginState,
            UserState,
            MasterDataState,
            Registries,
            SnackbarState,
            RequestsState,
            RolesState,
            ProtocolsState,
            VacancyState,
            RegisterMasterState,
            ProtocolsTypesState,
            EventsTypeState,
            ProposalsState,
            VacancyTypesState,
            PriorityState,
            RegisterMasterTypeState,
            RegisterRequestStatusLifeCycleState,
            RegisterRequestTypeState,
            GCRolesState,
            CCRolesState,
            UserRolesState,
            RegisterStatisticParametersMasterState,
            RegisterXpostFrequencyMasterState,
            RegisterAdTypeTimelineState,
            RegisterSponsorshipMasterState,
            RegisterEntityProcessCharMasterState,
            RegisterEntityProcessMasterState,
            RegisterRelationshipTypeMasterState,
            RegisterCharacteristicTypeMasterState,
            RegisterProcessTypeMasterState,
            RegisterDomainMasterState,
            RegisterEntityTypeMasterState,
            RegisterVotingStatusState,
            RegisterRequestProtocolLinkState,
            RegisterRequestEventLinkState,
            RegisterRequestObjectLinkState,
            RequestVacancyLinkState,
            RolesState,
            VacancyTypeState,
            ObjectNameState,
            StatState,
            EventTypeState,
            DomainMaster,
            ProtocolTypeMasterState,
            AssociatedProcessesMasterState,
            AssociatedCharacteristicsMasterState,
            ProcessTypeMasterState,
            RelationshipTypeMasterState,
            CharacteristicTypeDataState,
            SponsorshipTypeMasterState,
            ObjectTypeMasterState,
            PriorityTypeMasterState,
            RelationshipDirectionMasterState,
            EntityTypeMasterState,
            ParameterTypeMasterState,
            UserRequestTypeMasterState,
            MasterTypeState,
            MasterTableRoleDataState,
            MarketplaceRequestTransactionTypeDataState,
            TransactionTypeDataState,
            RequestTypeDataState,
            UserRequestTypeListState,
            ActiveVacancyState,
            ActiveEventsState,
            UserRequestState,
            ProposalState,
            RegisterAdTypeDataState,
        ]),
        NgxsStoragePluginModule.forRoot({ key: ["login", "user"] }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        BrowserAnimationsModule,
        AppRoutingModule,
    ],

    exports: [HeaderComponent, ConfirmationDialogComponent],
    providers: [
        ApiService,
        ConfirmationDialogService,
        DataCompanyService,
        AuthGuardService,
        SharedService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ConfirmationDialogComponent,
        NotFoundDataComponent,
        SnackbarComponent,
        HeaderComponent,
        ProposalDetailsDialog,
    ],
})
export class AppModule {}
