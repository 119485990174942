// user.action.ts

import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { SnackbarModel } from "./snackbar.model";
import {
    ShowSnackError,
    ShowSnackbarSuccess,
    ShowSnackbarWarning,
} from "./snackbar.actions";
import { SharedService } from "app/services/shared.service";

@State<SnackbarModel>({
    name: "snackbar",
    defaults: {
        type: null,
        message: null,
    },
})
export class SnackbarState {
    constructor(private store: Store, private sharedService: SharedService) {}
    @Action(ShowSnackError)
    ShowSnackError(
        { getState, patchState }: StateContext<SnackbarModel>,
        { payload }: any
    ) {
        if (payload) patchState({ type: "ERROR", message: payload.message });
    }
    @Action(ShowSnackbarSuccess)
    ShowSnackSuccess(
        { getState, patchState }: StateContext<SnackbarModel>,
        { payload }: any
    ) {
        patchState({ type: "SUCCESS", message: payload.message });
    }
    @Action(ShowSnackbarWarning)
    ShowSnackWarn(
        { getState, patchState }: StateContext<SnackbarModel>,
        { payload }: any
    ) {
        patchState({ type: "Warning", message: payload.message });
    }
}
