import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { NavigationComponent } from "app/navigation/navigation.component";
import { SharedService } from "app/services/shared.service";
import { CookieService } from "ngx-cookie-service";

@Component({
    selector: "vertical-layout-2",
    templateUrl: "./layout-2.component.html",
    styleUrls: ["./layout-2.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout2Component
    extends NavigationComponent
    implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    token;
    userKyc;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public sharedService: SharedService,
        private cookieService: CookieService
    ) {
        super(sharedService);
        // Set the defaults
        // this.navigation = this.navigation;

        this.sharedService.getnavigationData().subscribe((res) => {
            this.navigation = JSON.parse(res);
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.token = this.cookieService.get("token");
        this.userKyc = this.cookieService.get("userKYC");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
                this.token = this.cookieService.get("token");
                this.userKyc = this.cookieService.get("userKYC");
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
